import * as React from "react";
import { TableCell } from "@mui/material";
import { display, padding, textAlign } from "@mui/system";

interface TituloProps {
  children?: React.ReactNode;
  colSpan?: number;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const customTableCellStyles = {
  padding: "0",
};

export default function CustomTableCell(props: TituloProps) {
  return <TableCell {...props} sx={props.style ? props.style : customTableCellStyles} />;
}
