import { NavigateFunction } from "react-router-dom";
import { IAccount } from "../../../types/Adm/Account/IAccount";
import { IAccounts } from "../../../types/Generic/IAccounts";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { sendPost } from "../../../utils/httpUtils";

const contasService = {
  consultaTodasContas: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
  ) {
    let url = `v1/admin/accounts/list?size=1000&page=0`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<IAccount>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      return response.content;
    } catch (error) {
      // Handle the error here if necessary
      console.log((error as any).message);
      return [];
    }
  },

  searchFilteredAccounts: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    page: number,
    itensPerPage: number,
    sort?: string,
    searchQuery?: string,
  ) {
    let url = `v1/admin/accounts/list?size=${itensPerPage}&page=${page}${sort ? `&sort=${sort}` : ""}`;
    const dados = {
      search: searchQuery,
    };
    console.log(dados);
    try {
      const response = await sendPost<IPaginatedList<IAccount>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      console.log("response:", response);
      return response;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  },

  getSelectedAccount: function (selectedAccount: string, accounts: IAccounts[]) {
    return accounts.find((account) => account.accountId.toString() === selectedAccount);
  },
};

export default contasService;
