import { Outlet, useNavigate } from "react-router-dom";

import Container from "@mui/material/Container";
import { styled, ThemeProvider } from "@mui/material/styles";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { Grid, Paper } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useContext, useEffect } from "react";
import { GlobalContext, GlobalProvider } from "../../../core/context/GlobalContext";
import { createTheme } from "../../../core/theme/main";
import { IToken } from "../../../core/types/Generic/IToken";
import { MessageComponent } from "../../elements/Message";
import BarraLateral from "../BarraLateral";
import BarraSuperior from "../BarraSuperior";
import Loading from "../Loading";

dayjs.locale("pt-br");

const Principal = () => {
  const SIDE_NAV_WIDTH = 280;

  const LayoutRoot = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.up("xl")]: {
      paddingLeft: SIDE_NAV_WIDTH,
    },
  }));

  const LayoutContainer = styled("div")({
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    width: "100%",
  });

  const theme = createTheme();

  let navigate = useNavigate();

  function isTokenExpired(token: string | null) {
    if (token === null) {
      return true;
    }
    const decoded = jwtDecode<IToken>(token);
    return decoded.exp < Date.now() / 1000;
  }

  function verificaLogin() {
    const token = sessionStorage.getItem("token");
    if (token === null || isTokenExpired(token)) {
      sessionStorage.removeItem("token");
      navigate("/");
    }
  }
  const { loading } = useContext(GlobalContext);

  useEffect(() => {
    verificaLogin();
  }, []);

  return (
    <>
      <GlobalProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale()}>
            <Grid container>
              <Grid item>
                <BarraLateral />
              </Grid>
              <Grid item xs>
                <BarraSuperior />
                <LayoutRoot>
                  <LayoutContainer>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                      <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                          <Outlet />
                          <MessageComponent />
                          <Loading />
                        </Paper>
                      </Grid>
                    </Container>
                  </LayoutContainer>
                </LayoutRoot>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </ThemeProvider>
      </GlobalProvider>
    </>
  );
};

export default Principal;
