import { NavigateFunction } from "react-router-dom";
import { IAccount } from "../../../types/Adm/Account/IAccount";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { IModule } from "../../../types/Adm/Module/IModule";
import { IRole } from "../../../types/Adm/Role/IRole";
import { IResponse } from "../../../types/Generic/IResponse";
import { sendPost } from "../../../utils/httpUtils";
import http from "../../http";

const modulesService = {
  searchAllModules: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
  ) {
    let url = `v1/admin/modules/list?size=1000&page=0`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<IModule>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      return response.content;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return [];
    }
  },

  searchFilteredModules: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    page: number,
    itensPerPage: number,
    sort?: string,
    searchQuery?: string,
  ) {
    let url = `v1/admin/modules/list?size=${itensPerPage}&page=${page}${sort ? `&sort=${sort}` : ""}`;
    const dados = {
      search: searchQuery,
    };
    console.log(dados);
    try {
      const response = await sendPost<IPaginatedList<IModule>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      console.log("response:", response);
      return response;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  },

  checkModules: function (modulesToCheck: String[], modules: IModule[]) {
    console.log(modules);
    console.log(modulesToCheck);
    if (modules.some((module) => modulesToCheck.includes(module.name))) {
      return true;
    }
    return false;
  },
};

export default modulesService;
