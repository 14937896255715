import { NavigateFunction } from "react-router-dom";
import { sendPost } from "../../../utils/httpUtils";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { IClosure } from "../../../types/Cre/Closure/IClosure";

const closureService = {
    searchFilteredClosure: async function (
        addMessages: (messages: string[]) => void,
        setaLoading: (loading: boolean) => void,
        navigate: NavigateFunction,
        page: number,
        itensPerPage: number,
        sort?: string,
        walletId?: string,
        initialClosureDate?: Date,
        finalClosureDate?: Date,
        searchQuery?: string,
      ) {
        let url = `v1/closure/list?size=${itensPerPage}&page=${page}${sort ? `&sort=${sort}` : ""}`;
        const dados = {
          initialDueDate: initialClosureDate,
          finalDueDate: finalClosureDate,
          search: searchQuery,
            walletId: walletId,
        };
        console.log(dados);
        try {
          const response = await sendPost<IPaginatedList<IClosure>>(
            url,
            dados,
            addMessages,
            setaLoading,
            navigate,
          );
          console.log("response:", response);
          return response;
        } catch (error) {
          // Handle the error here if necessary
          console.log(error);
        }
      },

}

export default closureService;