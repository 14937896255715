import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";

import "./DetalhesFechamento.css";

import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";

import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CustomTableCell from "../../../../../components/elements/CustomTableCell";
import Paginacao from "../../../../../components/elements/Paginacao";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import { IClosureDetails } from "../../../../types/Cre/Closure/IClosureDetails";
import { IClosure } from "../../../../types/Cre/Closure/IClosure";
import { IPaginatedList } from "../../../../types/Generic/IPaginatedList";
import { sendGet, sendPost } from "../../../../utils/httpUtils";
import { formataDateLocal } from "../../../../utils/date-utils";
import CustomTable from "../../../../../components/elements/Crud/CustomTable";
import CustomTableContainer from "../../../../../components/elements/Crud/CustomTableContainer";
import CustomTableBody from "../../../../../components/elements/Crud/CustomTableBody";
import CustomStripedTableRow from "../../../../../components/elements/Crud/CustomStripedTableRow";
import TableHeader from "../../../../../components/elements/TableHeader";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DetalhesFechamento = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  let navigate = useNavigate();

  const { id } = useParams();
  const closureId = id;
  const [openImport, setOpenImport] = React.useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [fechamentoClientes, setFechamentoClientes] = useState<IClosureDetails[]>([]);
  const [fechamento, setFechamento] = useState<IClosure>();

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>("sp.NAME,desc");

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };

  async function consultaDetalheFechamento(page: number, sort?: string) {
    let url = `v1/closure/details/${closureId}?size=10&page=${page}${sort ? `&sort=${sort}` : ""}`;
    const dados = { closureId: closureId };
    try {
      const response = await sendPost<IPaginatedList<IClosureDetails>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setTotalPages(response.totalPages);
      setFechamentoClientes(response.content);
      setCurrentPage(page + 1);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function consultaFechamento() {
    let url = `v1/closure/${closureId}`;
    const dados = {};
    try {
      const response = await sendGet<IClosure>(url, dados, addMessages, setaLoading, navigate);
      setFechamento(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    consultaFechamento();
    consultaDetalheFechamento(0);
  }, []);

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    consultaDetalheFechamento(page - 1);
  };

  function handleEdit(row: IClosureDetails): void {
    throw new Error("Function not implemented.");
  }

  function handleDelete(row: IClosureDetails): void {
    throw new Error("Function not implemented.");
  }

  function handleClickVoltar() {
    navigate("/area-logada/fechamentos");
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    consultaDetalheFechamento(currentPage - 1, sortNovo);
    console.log(sortNovo);
  }

  function formatarNomeCliente(nomeCliente: string) {
    nomeCliente = nomeCliente.trim();
    // Limitando a 10 caracteres e adicionando '...'
    if (nomeCliente.length > 23) {
      //console.log("nomeCliente");
      //console.log("nomeCliente.substring(0, 20)");
      //console.log(nomeCliente);
      //console.log(nomeCliente.substring(0, 20));
      //console.log("nomeCliente.length - 3");
      //console.log(nomeCliente.length - 3);
      //console.log("nomeCliente.length");
      //console.log(nomeCliente.length);
      //console.log("nomeCliente.substring(nomeCliente.length - 3, nomeCliente.length)");
      //console.log(nomeCliente.substring(nomeCliente.length - 3, nomeCliente.length));
      return (
        nomeCliente.substring(0, 20) +
        " ... " +
        nomeCliente.substring(nomeCliente.length - 3, nomeCliente.length)
      );
    } else {
      return nomeCliente;
    }
  }

  const columns = [
    { displayName: "Cliente", columnName: "sp.NAME" },
    { displayName: "Telefone", columnName: "sp.WHATSAPP" },
    { displayName: "Total Notas de Venda", columnName: "totalNotasVenda" },
    { displayName: "Total Notas Devolução", columnName: "totalNotasDevolucao" },
    { displayName: "Total Duplicatas", columnName: "totalDuplicatas" },
  ];
  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Stack spacing={1}>
                <Titulo>
                  Detalhes Fechamento
                  <br />{" "}
                </Titulo>
                {fechamento && (
                  <ul>
                    Dia {formataDateLocal(fechamento.closureDate + "T00:00:00-03:00")}
                    <br />
                    {fechamento.carteirasClientes?.map((row1) => (
                      <li key={row1.walletNumber}>
                        {row1.walletNumber} - {row1.walletName}: {row1?.qtdClientes} <br />
                      </li>
                    ))}
                    <li key={"total"}>
                      Saldo Total: {`R$ ${fechamento.saldoTotal.toLocaleString("pt-BR")}`}
                    </li>
                  </ul>
                )}
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Exportar
                  </Button>
                </Stack>
              </Stack>
              <div>
                <Button
                  disabled
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Novo
                </Button>
              </div>
            </Stack>
            <div>
              <CustomTableContainer>
                <CustomTable>
                  <TableHeader
                    initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                    initialSortedField={sort.split(",")[0]}
                    columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                    handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                  />
                  <CustomTableBody>
                    {fechamentoClientes.map((row, index) => (
                      <CustomStripedTableRow row={Number(row.clientNumber)} index={index}>
                        <CustomTableCell>
                          <Tooltip title={row.clientName}>
                            <span>
                              {row.clientNumber} - {formatarNomeCliente(row.clientName)}
                            </span>
                          </Tooltip>
                        </CustomTableCell>
                        <CustomTableCell>{row.whatsapp}</CustomTableCell>
                        <CustomTableCell>{`R$ ${row.totalNotasVenda.toLocaleString("pt-BR")}`}</CustomTableCell>
                        <CustomTableCell>{`R$ ${row.totalNotasDevolucao.toLocaleString("pt-BR")}`}</CustomTableCell>
                        <CustomTableCell>{`R$ ${row.totalDuplicata.toLocaleString("pt-BR")}`}</CustomTableCell>
                      </CustomStripedTableRow>
                    ))}
                  </CustomTableBody>
                  <Paginacao
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePageChange={handlePageChange}
                  />
                </CustomTable>
              </CustomTableContainer>
            </div>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default DetalhesFechamento;
