import { NavigateFunction } from "react-router-dom";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { sendGet, sendPost } from "../../../utils/httpUtils";
import { ISimpleList } from "../../../types/Generic/ISimpleList";
import { ICapRecurrency } from "../../../types/Cap/Recurrency/ICapRecurrency";
import { ISimpleListStringString } from "../../../types/Generic/ISimpleListStringString";

const recurrencyService = {
  searchAllCapRecurrencies: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
  ) {
    let url = `v1/cap/recurrency/list?size=1000&page=0`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<ICapRecurrency>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      return response.content;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return [];
    }
  },

  searchFilteredCapRecurrencies: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
    page: number,
    itensPerPage: number,
    sort?: string,
    initialDate?: Date,
    finalDate?: Date,
    searchQuery?: string,
  ) {
    let url = `v1/cap/recurrency/list?size=5&page=${page}${sort ? `&sort=${sort}` : ""}`;
    const dados = {
      initialDueDate: initialDate,
      finalDueDate: finalDate,
      search: searchQuery
    };
    console.log(dados);
    try {
      const response = await sendPost<IPaginatedList<ICapRecurrency>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      return response;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  },
  searchPossibleTimeMeasureList: async function (
    addMessages: (messages: string[]) => void,
    setaLoading: (loading: boolean) => void,
    navigate: NavigateFunction,
  ) {
    let url = `v1/cap/recurrency/possible-recurrency-time-measure`;
    const dados = {};
    try {
      const response = await sendGet<ISimpleListStringString[]>(url, dados, addMessages, setaLoading, navigate);
      return response;
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
      return [];
    }
  },
};

export default recurrencyService;
