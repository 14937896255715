import Pagination from "@mui/lab/Pagination";
import PaginationItem from "@mui/lab/PaginationItem";
import { MenuItem, Select, SelectChangeEvent, TableFooter, TableRow } from "@mui/material";
import CustomTableCell from "../CustomTableCell";
import "./Paginacao.css";

interface PaginacaoProps {
  totalPages: number;
  currentPage: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  totalRecords?: number;
  itensPerPage?: number;
  handleItemsPerPageChange?: (value: number) => void;
}

const Paginacao = ({ totalPages, currentPage, handlePageChange, totalRecords, itensPerPage: itemsPerPage, handleItemsPerPageChange }: PaginacaoProps) => {
  const handleItemsPerPageChangeWrapper = (event: SelectChangeEvent<number>) => {
    if(handleItemsPerPageChange)
      handleItemsPerPageChange(event.target.value as number);
  };
  return (
    <>
        <TableFooter>
          <TableRow>
            <CustomTableCell style={{display:"flex", alignContent: "center", alignItems: "center"}}>
              {totalRecords && totalPages && handleItemsPerPageChange && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <span>Regs: {totalRecords}</span>
                  <span> | Págs: {totalPages}&nbsp;</span>
                </div>
              </div>)}
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                shape="rounded"
                showFirstButton={true}
                showLastButton={true}
                siblingCount={10}
                boundaryCount={1}
                size="small"
                variant="outlined"
                renderItem={(item) => <PaginationItem component="a" {...item} />}
              />
              
              {totalRecords && totalPages && handleItemsPerPageChange && (
                <div>
                  &nbsp;<Select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChangeWrapper}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Itens por página' }}
                    sx={{ height: '30px', fontSize: '14px' }} // Ajuste a altura e o tamanho da fonte
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </div>
                )}
            </CustomTableCell>
          </TableRow>
        </TableFooter>
    </>
  );
};

export default Paginacao;
