import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate, useParams } from "react-router-dom";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import { ICreClient } from "../../../../types/Cre/Client/ICreClient";
import { ICreClientEditData } from "../../../../types/Cre/Client/ICreClientEditData";
import { IModule } from "../../../../types/Adm/Module/IModule";
import { sendGet, sendPost, sendSimpleGet } from "../../../../utils/httpUtils";
import "./EditClient.css";
import { IMaskInput } from "react-imask";
import { ICep } from "../../../../types/Generic/ICep";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CepMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CepMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00000-000"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const PhoneMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function PhoneMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={[{ mask: "+55 (00) 0000-0000" }, { mask: "+55 (00) 00000-0000" }]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const WhatsAppMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function WhatsAppMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={[{ mask: "+55 (00) 0000-0000" }, { mask: "+55 (00) 00000-0000" }]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const CpfMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CpfMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000.000.000-00"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const CnpjMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CnpjMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00.000.000/000#-00"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const EditClient = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const { id } = useParams();
  const clientId = Number(id);

  const [client, setClient] = useState<ICreClientEditData>({
    id: clientId,
    peopleName: "",
    peopleFantasyName: "",
    peopleCpfCnpj: "",
    peopleEmail: "",
    municipalInscription: "",
    stateInscription: "",
    addressPostalCode: "",
    addressStreet: "",
    addressNumber: "",
    addressComplement: "",
    addressNeighborhood: "",
    addressCity: "",
    addressState: "",
    addressRegion: "",
    whatsapp: "",
    fixedPhone: "",
    peopleInstagram: "",
    clientNumber: "",
    peopleAdditionalEmails: "",
    observations: "",
  } as ICreClientEditData);

  const [peopleType, setPeopleType] = useState("");

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/clients");
  }

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function validateCpf(cpf: string) {
    if (cpf.length !== 11) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  }

  function validateCnpj(cnpj: string) {
    if (cnpj === "" || cnpj.length !== 14) {
      return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999"
    ) {
      return false;
    }

    // Valida DVs
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    const digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += Number(numbers.charAt(size - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(0))) {
      return false;
    }

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += Number(numbers.charAt(size - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(1))) {
      return false;
    }

    return true;
  }

  function validateForm() {
    //Aplicar trim em todos os campos
    //colocar verificação de null ou undefined antes do trim
    if (client.peopleName !== null && client.peopleName !== undefined) {
      client.peopleName = client.peopleName.trim();
    }
    if (client.peopleCpfCnpj !== null && client.peopleCpfCnpj !== undefined) {
      client.peopleCpfCnpj = client.peopleCpfCnpj.trim().replace(/\D/g, "");
    }
    if (client.peopleEmail !== null && client.peopleEmail !== undefined) {
      client.peopleEmail = client.peopleEmail.trim();
    }
    if (client.addressPostalCode !== null && client.addressPostalCode !== undefined) {
      client.addressPostalCode = client.addressPostalCode.trim().replace(/\D/g, "");
    }
    if (client.addressStreet !== null && client.addressStreet !== undefined) {
      client.addressStreet = client.addressStreet.trim();
    }
    if (client.addressComplement !== null && client.addressComplement !== undefined) {
      client.addressComplement = client.addressComplement.trim();
    }
    if (client.addressNeighborhood !== null && client.addressNeighborhood !== undefined) {
      client.addressNeighborhood = client.addressNeighborhood.trim();
    }
    if (client.addressCity !== null && client.addressCity !== undefined) {
      client.addressCity = client.addressCity.trim();
    }
    if (client.addressState !== null && client.addressState !== undefined) {
      client.addressState = client.addressState.trim();
    }
    if (client.addressRegion !== null && client.addressRegion !== undefined) {
      client.addressRegion = client.addressRegion.trim();
    }
    if (client.whatsapp !== null && client.whatsapp !== undefined) {
      client.whatsapp = client.whatsapp.trim().replace(/\D/g, "");
    }
    if (client.fixedPhone !== null && client.fixedPhone !== undefined) {
      client.fixedPhone = client.fixedPhone.trim().replace(/\D/g, "");
    }
    if (client.peopleInstagram !== null && client.peopleInstagram !== undefined) {
      client.peopleInstagram = client.peopleInstagram.trim();
    }
    if (client.clientNumber !== null && client.clientNumber !== undefined) {
      client.clientNumber = client.clientNumber.trim();
    }
    if (client.peopleAdditionalEmails !== null && client.peopleAdditionalEmails !== undefined) {
      client.peopleAdditionalEmails = client.peopleAdditionalEmails.trim();
    }
    if (client.observations !== null && client.observations !== undefined) {
      client.observations = client.observations.trim();
    }

    if (peopleType !== "F") {
      if (client.peopleFantasyName !== null && client.peopleFantasyName !== undefined) {
        client.peopleFantasyName = client.peopleFantasyName.trim();
      }
      if (client.municipalInscription !== null && client.municipalInscription !== undefined) {
        client.municipalInscription = client.municipalInscription.trim();
      }
      if (client.stateInscription !== null && client.stateInscription !== undefined) {
        client.stateInscription = client.stateInscription.trim();
      }
    } else {
      client.peopleFantasyName = "";
      client.municipalInscription = "";
      client.stateInscription = "";
    }

    if (client.peopleName === "") {
      addMessages(["Nome é obrigatório"]);
      return false;
    }
    if (client.peopleCpfCnpj === "") {
      addMessages(["CPF/CNPJ é obrigatório"]);
      return false;
    } else {
      if (peopleType === "F") {
        if (!validateCpf(client.peopleCpfCnpj)) {
          addMessages(["CPF inválido"]);
          return false;
        }
      } else {
        if (!validateCnpj(client.peopleCpfCnpj)) {
          addMessages(["CNPJ inválido"]);
          return false;
        }
      }
    }
    if (client.addressPostalCode === "") {
      addMessages(["CEP do endereço é obrigatório"]);
      return false;
    }
    if (client.addressNumber === "") {
      addMessages(["Número do endereço é obrigatório"]);
      return false;
    }

    if (client.peopleEmail !== "" && client.peopleEmail !== null) {
      if (!validateEmail(client.peopleEmail)) {
        addMessages(["Email inválido"]);
        console.log("Email inválido: " + client.peopleEmail);
        return false;
      }
    }

    console.log("Whatsapp: " + client.whatsapp);
    if (client.whatsapp !== null && client.whatsapp !== undefined && client.whatsapp !== "") {
      if (client.whatsapp.length > 13 || client.whatsapp.length < 12) {
        addMessages(["Whatsapp inválido"]);
        return false;
      }
    }
    console.log("Telefone: " + client.fixedPhone);
    if (client.fixedPhone !== null && client.fixedPhone !== undefined && client.fixedPhone !== "") {
      if (client.fixedPhone.length > 13 || client.fixedPhone.length < 12) {
        addMessages(["Telefone inválido"]);
        return false;
      }
    }
    return true;
  }

  async function handleClickSalvar() {
    if (!validateForm()) {
      return;
    }
    let url = `v1/client/edit/${clientId}`;
    const dados = {
      id: client.id,
      name: client.peopleName,
      cpfCnpj: client.peopleCpfCnpj,
      number: client.clientNumber,
      fantasyName: client.peopleFantasyName,
      email: client.peopleEmail,
      whatsapp: client.whatsapp,
      fixedPhone: client.fixedPhone,
      address: client.addressStreet,
      addressNumber: client.addressNumber,
      complement: client.addressComplement,
      province: client.addressNeighborhood,
      city: client.addressCity,
      state: client.addressState,
      postalCode: client.addressPostalCode,
      region: client.addressRegion,
      municipalInscription: client.municipalInscription,
      stateInscription: client.stateInscription,
      observations: client.observations,
      instagram: client.peopleInstagram,
      additionalEmails: client.peopleAdditionalEmails,
    };
    try {
      const response = await sendPost<ICreClient>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/clients");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setClient((prevClient) => ({ ...prevClient, [name]: valor }));

    if (name === "addressPostalCode" && valor.length === 9) {
      fetchCep(valor);
    }
  };

  async function fetchCep(cep: string) {
    let url = `https://viacep.com.br/ws/${cep}/json/`;
    const dados = {};
    //console.log(dados)
    try {
      const response = await sendSimpleGet<ICep>(url, dados, addMessages, setaLoading, navigate);
      console.log(response);
      setClient((prevClient) => ({
        ...prevClient,
        addressStreet: response.logradouro,
        addressNeighborhood: response.bairro,
        addressCity: response.localidade,
        addressState: response.uf,
      }));
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function consultaClient() {
    let url = `v1/client/${clientId}`;
    const dados = {};
    try {
      const response = await sendGet<ICreClient>(url, dados, addMessages, setaLoading, navigate);
      setClient(response);
      console.log(response);
      if (response.peopleCpfCnpj.length > 11) {
        setPeopleType("J");
      } else {
        setPeopleType("F");
      }
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    consultaClient();
  }, []);

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Editar Cliente</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="radio-buttons-group-people-type-label">Tipo Pessoa</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-people-type-label"
                    name="peopleType"
                  >
                    <FormControlLabel
                      value="F"
                      control={<Radio />}
                      checked={peopleType === "F"}
                      label="Física"
                      disabled
                    />
                    <FormControlLabel
                      value="J"
                      control={<Radio />}
                      checked={peopleType === "J"}
                      label="Jurídica"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={peopleType === "F" ? "Nome" : "Razão Social"}
                  value={client.peopleName}
                  name="peopleName"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>

              {peopleType === "J" ? (
                <Grid item xs={12}>
                  <TextField
                    label="Nome Fantasia"
                    value={client.peopleFantasyName}
                    name="peopleFantasyName"
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <TextField
                  label="CPF/CNPJ"
                  value={client.peopleCpfCnpj}
                  name="peopleCpfCnpj"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={
                    peopleType === "F"
                      ? { inputComponent: CpfMaskCustom as any }
                      : { inputComponent: CnpjMaskCustom as any }
                  }
                  disabled
                />
              </Grid>
              {peopleType === "J" ? (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label="Inscrição Municipal"
                      value={client.municipalInscription}
                      name="municipalInscription"
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Inscrição Estadual"
                      value={client.stateInscription}
                      name="stateInscription"
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  value={client.peopleEmail}
                  name="peopleEmail"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="CEP"
                  value={client.addressPostalCode}
                  name="addressPostalCode"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: CepMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Rua"
                  value={client.addressStreet}
                  name="addressStreet"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Numero"
                  value={client.addressNumber}
                  name="addressNumber"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Complemento"
                  value={client.addressComplement}
                  name="addressComplement"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Bairro"
                  value={client.addressNeighborhood}
                  name="addressNeighborhood"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Cidade"
                  value={client.addressCity}
                  name="addressCity"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Estado"
                  value={client.addressState}
                  name="addressState"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Região"
                  value={client.addressRegion}
                  name="addressRegion"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="WhatsApp"
                  value={client.whatsapp}
                  name="whatsapp"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: WhatsAppMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Celular"
                  value={client.fixedPhone}
                  name="fixedPhone"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: PhoneMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Instagram"
                  value={client.peopleInstagram}
                  name="peopleInstagram"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Numero do Cliente"
                  value={client.clientNumber}
                  name="clientNumber"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Emails Adicionais"
                  value={client.peopleAdditionalEmails}
                  name="peopleAdditionalEmails"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observações"
                  value={client.observations}
                  name="observations"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default EditClient;
