import * as React from "react";

import { Link, useNavigate } from "react-router-dom";

import BellIcon from "@heroicons/react/24/solid/BellIcon";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import UserCircleIcon from "@heroicons/react/24/solid/UserCircleIcon";
import ArrowRightOnRectangleIcon from "@heroicons/react/24/solid/ArrowRightOnRectangleIcon";
import { Badge, Box, IconButton, Stack, SvgIcon, Tooltip, useMediaQuery } from "@mui/material";
import { alpha } from "@mui/material/styles";
const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

const BarraSuperior = () => {
  let navigate = useNavigate();

  let token = sessionStorage.getItem("token");

  const [usuarioEstaLogado, setUsuarioEstaLogado] = React.useState<boolean>(token != null);

  const temPesquisa = false;

  const temContatos = false;

  const temNotificacoes = true;

  React.useEffect(() => {
    token = sessionStorage.getItem("token");
    setUsuarioEstaLogado(token != null);
    if (!usuarioEstaLogado) {
      navigate("/");
    }
  });
  const efetuarLogout = () => {
    setUsuarioEstaLogado(false);
    sessionStorage.removeItem("token");
    navigate("/");
  };

  const navegarPaginaConta = () => {
    navigate("/area-logada/conta");
  };
  const pegaNotificacao = () => {
    return null;
  };
  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
          position: "sticky",
          left: {
            lg: `${SIDE_NAV_WIDTH}px`,
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
          },
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {temPesquisa && (
              <Tooltip title="Search">
                <IconButton>
                  <SvgIcon fontSize="small">
                    <MagnifyingGlassIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            )}
          </Stack>

          <Stack alignItems="center" direction="row" spacing={2}>
            {temContatos && (
              <Tooltip title="Contacts">
                <IconButton>
                  <SvgIcon fontSize="small">
                    <UsersIcon />
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Conta">
              <IconButton onClick={navegarPaginaConta}>
                <SvgIcon fontSize="small">
                  <UserCircleIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
            {temNotificacoes && (
              <IconButton disabled>
                <Badge badgeContent={pegaNotificacao()} color="success" variant="standard">
                  <Tooltip title="Notifications">
                    <SvgIcon fontSize="small">
                      <BellIcon />
                    </SvgIcon>
                  </Tooltip>
                </Badge>
              </IconButton>
            )}

            <Tooltip title="Logout">
              <IconButton onClick={efetuarLogout}>
                <SvgIcon fontSize="small">
                  <ArrowRightOnRectangleIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default BarraSuperior;
