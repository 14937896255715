import React, { ChangeEvent, useContext, useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  Paper,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  makeStyles,
} from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import UserCircleIcon from "@heroicons/react/24/solid/UserCircleIcon";

import Titulo from "../../../../../components/elements/Titulo";
import "./EdicaoMenus.css";
import { useNavigate, useParams } from "react-router-dom";
import { IMenuNewData } from "../../../../types/Adm/Menu/IMenuNewData";
import http from "../../../../services/http";
import { IResponse } from "../../../../types/Generic/IResponse";
import { IMenu } from "../../../../types/Adm/Menu/IMenu";
import { IMenuEditData } from "../../../../types/Adm/Menu/IMenuEditData";
import { IRole } from "../../../../types/Adm/Role/IRole";
import rolesService from "../../../../services/admin/RolesService";
import RolesCheckboxGrid from "../../../../../components/elements/grids/RolesCheckboxGrid";
import { sendGet, sendPost } from "../../../../utils/httpUtils";
import { GlobalContext } from "../../../../context/GlobalContext";
import { IPaginatedList } from "../../../../types/Generic/IPaginatedList";

const EdicaoMenus = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const { id } = useParams();
  const menuId = Number(id);

  const [menu, setMenu] = useState<IMenuEditData>({
    id: menuId,
    menuTitle: "",
    menuPath: "",
    menuIcon: "",
    menuOrder: null,
    menuParentId: null,
    roles: [],
  } as IMenuEditData);

  const [menuList, setMenuList] = useState<IMenu[]>([] as IMenu[]);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/admin/menus");
  }

  async function handleClickSalvar() {
    let url = `v1/admin/menus/edit/${menuId}`;
    const dados = menu;
    try {
      const response = await sendPost<IMenu>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/admin/menus");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    papel?: IRole,
  ) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = type === "checkbox" ? target.checked : target.value;

    if (type === "checkbox" && papel) {
      if (valor) {
        setMenu((prevMenu) => ({ ...prevMenu, roles: [...prevMenu.roles, papel] }));
      } else {
        setMenu((prevMenu) => ({
          ...prevMenu,
          roles: prevMenu.roles.filter((role) => role.id !== papel.id),
        }));
      }
    } else {
      setMenu((prevMenu) => ({ ...prevMenu, [name]: valor }));
    }
  };

  async function consultaMenu() {
    let url = `v1/admin/menus/${menuId}`;
    const dados = {};
    try {
      const response = await sendGet<IMenu>(url, dados, addMessages, setaLoading, navigate);
      setMenu(response);
      searchMenus("", response.menuParentId);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchMenus(menuName: string, menuId: number | null) {
    let url = `v1/admin/menus/list?size=10&page=0&sort=MENU_ORDER,asc`;
    const dados = { menuTitle: menuName, id: menuId };
    try {
      const response = await sendPost<IPaginatedList<IMenu>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setMenuList(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    consultaMenu();
  }, []);

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Editar Menu</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Título"
                  value={menu.menuTitle}
                  name="menuTitle"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Rota"
                  value={menu.menuPath}
                  name="menuPath"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Ícone"
                  value={menu.menuIcon}
                  name="menuIcon"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Ordem"
                  type="number"
                  value={menu.menuOrder}
                  name="menuOrder"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-menu"
                  options={menuList}
                  getOptionLabel={(option) =>
                    (option.menuParentTitle ? option.menuParentTitle + " -> " : "") +
                    option.menuTitle
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  value={menuList.find((men) => men.id === menu.menuParentId) || null} // Defina o valor padrão
                  onChange={(event, newValue) => {
                    setMenu((prevMenu) => ({
                      ...prevMenu,
                      menuParentId: newValue ? newValue.id : null,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Menu Pai" />}
                  onInputChange={(event, newInputValue) => {
                    searchMenus(newInputValue, menu.menuParentId);
                  }}
                />
              </Grid>

              <RolesCheckboxGrid papeisSelecionados={menu.roles} handleChange={handleChange} />

              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default EdicaoMenus;
