import * as React from "react";
import Typography from "@mui/material/Typography";

interface TituloProps {
  children?: React.ReactNode;
}

export default function Titulo(props: TituloProps) {
  return (
    <Typography component="h2" variant="h4" color="primary" style={{ whiteSpace: "nowrap" }}>
      {props.children}
    </Typography>
  );
}
