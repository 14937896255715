import { TableRow } from "@mui/material";

interface CustomStripedTableRowProps {
  children?: React.ReactNode;
  row: any;
  index: number;
}

export default function CustomStripedTableRow(props: CustomStripedTableRowProps) {
  return (
    <TableRow
      key={props.row.id}
      sx={{ backgroundColor: props.index % 2 === 0 ? "#ffffff" : "#eeeeff" }}
    >
      {props.children}
    </TableRow>
  );
}
