import React, { useContext } from "react";
import { Snackbar } from "@mui/material";
import { GlobalContext } from "../../../core/context/GlobalContext";

export const MessageComponent: React.FC = () => {
  const { messages, openMessage, closeMessage } = useContext(GlobalContext);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    closeMessage();
  };

  return (
    <div>
      {messages && (
        <div>
          <Snackbar
            open={openMessage}
            autoHideDuration={6000}
            onClose={handleClose}
            message={messages.join(". ")}
          />
        </div>
      )}
    </div>
  );
};
