import React, { useContext, useState } from "react";

import { Box, Button, Container, Grid, Stack, SvgIcon, TextField } from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate } from "react-router-dom";
import Titulo from "../../../../../components/elements/Titulo";
import ModulesCheckboxGrid from "../../../../../components/elements/grids/ModulesCheckboxGrid";
import { GlobalContext } from "../../../../context/GlobalContext";
import { IModule } from "../../../../types/Adm/Module/IModule";
import { IRole } from "../../../../types/Adm/Role/IRole";
import { IPlanNewData } from "../../../../types/Adm/Plan/IPlanNewData";
import { sendPost } from "../../../../utils/httpUtils";
import "./InclusaoPlanos.css";

const InclusaoPlanos = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [plano, setPlano] = useState<IPlanNewData>({
    name: "",
    description: "",
    fixedValue: 0,
    variableValue: 0,
    variableMessage: "",
    modules: [],
  } as IPlanNewData);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/admin/planos");
  }

  async function handleClickSalvar() {
    let url = `v1/admin/plans/new`;
    const dados = {
      planName: plano.name,
      planDescription: plano.description,
      fixedValue: plano.fixedValue,
      variableValue: plano.variableValue,
      variableMessage: plano.variableMessage,
      active: true,
      modules: plano.modules,
    };
    console.log(dados);
    try {
      const response = await sendPost<IRole>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/admin/planos");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    module?: IModule,
  ) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setPlano((prevPlano) => ({ ...prevPlano, [name]: valor }));
  };

  const handleModuloChange = (event: React.ChangeEvent<HTMLInputElement>, module: IModule) => {
    const target = event.target as HTMLInputElement;
    const valor = target.checked;

    if (valor) {
      setPlano((prevPlano) => ({ ...prevPlano, modules: [...prevPlano.modules, module] }));
    } else {
      setPlano((prevPlano) => ({
        ...prevPlano,
        modules: prevPlano.modules.filter((module1) => module1.id !== module.id),
      }));
    }
  };

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Incluir Plano</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Nome"
                  value={plano.name}
                  name="name"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descrição"
                  value={plano.description}
                  name="description"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  label="Valor fixo"
                  value={plano.fixedValue}
                  name="fixedValue"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="number"
                  label="Valor variavel"
                  value={plano.variableValue}
                  name="variableValue"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Mensagem variavel"
                  value={plano.variableMessage}
                  name="variableMessage"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <ModulesCheckboxGrid
                selectedModules={plano.modules}
                handleChange={handleModuloChange}
              />
              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default InclusaoPlanos;
