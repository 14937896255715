import React, { useCallback, useContext, useEffect, useState } from "react";

import "./Wallet.css";

import PencilSquareIcon from "@heroicons/react/24/solid/PencilSquareIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";

import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";

import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  styled,
} from "@mui/material";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import CustomTableCell from "../../../../components/elements/CustomTableCell";
import Paginacao from "../../../../components/elements/Paginacao";
import TableHeader from "../../../../components/elements/TableHeader";
import Titulo from "../../../../components/elements/Titulo";
import { GlobalContext } from "../../../context/GlobalContext";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { ISuccessoMensagensResponse } from "../../../types/Generic/ISuccessoMensagensResponse";
import { IWallet } from "../../../types/Cre/Wallet/IWallet";
import { sendDelete, sendPost } from "../../../utils/httpUtils";
import EditAcctionButton from "../../../../components/elements/EditActionButton";
import DeleteActionButton from "../../../../components/elements/DeleteActionButton";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import CustomActionBox from "../../../../components/elements/Crud/CustomActionBox";
import walletService from "../../../services/admin/WalletService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Wallet = () => {
  let navigate = useNavigate();

  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [openImport, setOpenImport] = React.useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const [inputSearchValue, setInputSearchValue] = useState("");

  const [wallet, setWallet] = useState<IWallet[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>("WALLET_NAME,asc");

  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [itensPerPage, setItensPerPage] = useState<number>(5);

  const handleItemsPerPageChange = (itens: number) => {
    searchWallet(0, itens, sort);
  };

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  async function searchWallet(
    page: number,
    itensPerPage: number,
    sort?: string,
    walletName?: string,
  ) {
    setItensPerPage(itensPerPage);
    const response = await walletService.searchFilteredWallets(
      addMessages,
      setaLoading,
      navigate,
      page,
      itensPerPage,
      sort,
      walletName,
    );
    if (response) {
      setTotalPages(response.totalPages);
      setWallet(response.content);
      setCurrentPage(page + 1);
      setTotalRecords(response.totalElements);
    } else {
      setTotalPages(0);
      setWallet([]);
      addMessages(["Não houve resposta do serviço de carteiras"]);
    }
  }

  async function excluiWallet(id: number) {
    let url = `/v1/wallet/${id}`;
    const dados = {};
    try {
      const response = await sendDelete<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      addMessages(response.messages);
      searchWallet(0, itensPerPage, sort);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  let jaFez: boolean = false;

  // Função de atualização do estado que será chamada pelo debounce
  const updateDebouncedSearchValue = useCallback(
    debounce((value: string) => {
      setDebouncedSearchValue(value);
      console.log("Debounced value:", value);
      // Aqui você pode chamar sua função de busca ou lógica com value
      if (jaFez) {
        searchWallet(currentPage - 1, itensPerPage, sort, value);
      }
      jaFez = true;

      return;
    }, 1000),
    [],
  ); // 1000 ms de delay

  useEffect(() => {
    searchWallet(0, itensPerPage, sort);
  }, []);

  useEffect(() => {
    // Atualiza o valor debounced toda vez que o inputValue mudar

    updateDebouncedSearchValue(inputSearchValue);
    return updateDebouncedSearchValue.cancel; // Limpa o timeout anterior toda vez que o valor mudar
  }, [inputSearchValue, updateDebouncedSearchValue]);

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchWallet(page - 1, itensPerPage, sort);
  };

  function handleClickEditar(wallet: IWallet): void {
    navigate(`/area-logada/edit-wallet/${wallet.id}`);
  }

  function handleClickNovo(): void {
    navigate(`/area-logada/new-wallet`);
  }

  function handleClickExcluir(
    wallet: IWallet,
  ): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (confirm(`Tem certeza que deseja excluir o papel ${wallet.walletName}`)) {
      excluiWallet(wallet.id);
    }
    return;
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    searchWallet(currentPage - 1, itensPerPage, sortNovo);
    console.log(sortNovo);
  }

  const columns = [
    { displayName: "Id", columnName: "ID" },
    { displayName: "Número", columnName: "WALLET_NUMBER" },
    { displayName: "Nome", columnName: "WALLET_NAME" },
    { displayName: "Usuário", columnName: "USER_UPDATE_ID" },
    { displayName: "Data de criação", columnName: "CREATE_DATE" },
    { displayName: "Usuário de atualização", columnName: "USER_UPDATE_NAME" },
    { displayName: "Ações", columnName: null },
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Carteiras</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    }
                    onClick={handleOpenImport}
                  >
                    Importar
                  </Button>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Exportar
                  </Button>
                </Stack>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={1}>
                <TextField
                  fullWidth
                  label="Busca"
                  variant="outlined"
                  value={inputSearchValue}
                  onChange={(e) => setInputSearchValue(e.target.value)}
                  placeholder="Digite para buscar..."
                />
                <Button
                  onClick={() => handleClickNovo()}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {wallet.map((row, index) => (
                    <CustomStripedTableRow row={row.id} index={index}>
                      <CustomTableCell>{row.id}</CustomTableCell>
                      <CustomTableCell>{row.walletNumber}</CustomTableCell>
                      <CustomTableCell>{row.walletName}</CustomTableCell>
                      <CustomTableCell>{row.userUpdateId}</CustomTableCell>
                      <CustomTableCell>{row.createDate}</CustomTableCell>
                      <CustomTableCell>{row.userUpdateName}</CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          <EditAcctionButton row={row} handleClickEditar={handleClickEditar} />
                          <DeleteActionButton row={row} handleClickExcluir={handleClickExcluir} />
                        </CustomActionBox>
                      </CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
              </CustomTable>
            </CustomTableContainer>
            <Paginacao
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              totalRecords={totalRecords}
              itensPerPage={itensPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Wallet;
