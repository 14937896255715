import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate } from "react-router-dom";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import { IPaginatedList } from "../../../../types/Generic/IPaginatedList";
import { sendPost } from "../../../../utils/httpUtils";
import "./NewBankAccount.css";
import { IBankAccountNewData } from "../../../../types/Ceb/IBankAccountNewData";
import { IBankAccount } from "../../../../types/Ceb/IBankAccount";

const NewBankAccount = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  //Inicia todas as propriedadese do objeto como string vazia
  const [bankAccount, setBankAccount] = useState<IBankAccountNewData>({
    name: "",
  } as IBankAccountNewData);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/ceb/bank-account");
  }

  function validateForm() {
    //Aplicar trim em todos os campos
    bankAccount.name = bankAccount.name.trim();
    if (bankAccount.name === "") {
      addMessages(["Nome é obrigatório"]);
      return false;
    }

    return true;
  }
  async function handleClickSave() {
    if (!validateForm()) {
      return;
    }

    let url = `v1/ceb/bank-account/new`;
    const dados = {
      name: bankAccount.name,
    };
    //console.log(dados)
    try {
      const response = await sendPost<IBankAccount>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/ceb/bank-account");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchBankAccount(bankAccountName: string) {
    let url = `v1/ceb/bank-account/list?size=10&page=0&sort=NAME,asc`;
    const dados = { name: bankAccountName };
    try {
      const response = await sendPost<IPaginatedList<IBankAccount>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setBankAccount((prevBankAccount) => ({ ...prevBankAccount, [name]: valor }));
  };

  useEffect(() => {
    setaLoading(true);
    searchBankAccount("");
    setaLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Incluir Conta Bancaria</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Nome"
                  name="name"
                  value={bankAccount.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={handleClickSave}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default NewBankAccount;
