import React, { useContext, useState } from "react";

import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  SvgIcon,
  TextField,
  TextFieldProps,
} from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate } from "react-router-dom";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import { sendPost } from "../../../../utils/httpUtils";
import "./NewPayment.css";
import { DatePicker } from "@mui/x-date-pickers";
import { ICapPaymentNewData } from "../../../../types/Cap/Payment/ICapPaymentNewData";
import { ICapPayment } from "../../../../types/Cap/Payment/ICapPayment";

const CapNewPayment = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [payment, setPayment] = useState<ICapPaymentNewData>({
    paymentValue: 0,
    paymentDate: new Date(),
    isTotal: false,
  } as ICapPaymentNewData);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/cap/payment");
  }

  async function handleClickSalvar() {
    let url = `v1/cap/payment/new`;
    const dados = {
      paymentValue: payment.paymentValue,
      paymentDate: payment.paymentDate,
      isTotal: payment.isTotal,
    };
    console.log(dados);
    try {
      const response = await sendPost<ICapPayment>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/cap/payment");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setPayment((prevPayment) => ({ ...prevPayment, [name]: valor }));
  };

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Incluir Pagamentos</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Valor"
                  value={payment.paymentValue}
                  name="paymentValue"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Total"
                  value={payment.isTotal}
                  name="isTotal"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Data Pagamento"
                  value={payment.paymentDate}
                  onChange={(newValue: Date | null) => {
                    setPayment((prevPayment) => ({
                      ...prevPayment,
                      paymentDate: newValue ? newValue : new Date(),
                    }));
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 245 }} name="paymentDate" />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default CapNewPayment;
