import React, { useContext, useEffect, useState } from "react";

import "./Modules.css";

import PencilSquareIcon from "@heroicons/react/24/solid/PencilSquareIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";

import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";

import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTableCell from "../../../../components/elements/CustomTableCell";
import Paginacao from "../../../../components/elements/Paginacao";
import Titulo from "../../../../components/elements/Titulo";
import { GlobalContext } from "../../../context/GlobalContext";
import http from "../../../services/http";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { IModule } from "../../../types/Adm/Module/IModule";
import { IResponse } from "../../../types/Generic/IResponse";
import { ISuccessoMensagensResponse } from "../../../types/Generic/ISuccessoMensagensResponse";
import { formataTimeStampLocal } from "../../../utils/date-utils";
import { sendDelete, sendPost } from "../../../utils/httpUtils";
import DeleteActionButton from "../../../../components/elements/DeleteActionButton";
import EditAcctionButton from "../../../../components/elements/EditActionButton";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import TableHeader from "../../../../components/elements/TableHeader";
import { display } from "@mui/system";
import { nl } from "date-fns/locale";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import CustomActionBox from "../../../../components/elements/Crud/CustomActionBox";
import modulesService from "../../../services/admin/ModulesService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Modules = () => {
  let navigate = useNavigate();

  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [openImport, setOpenImport] = React.useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [modules, setModules] = useState<IModule[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [itensPerPage, setItensPerPage] = useState<number>(5);

  const [sort, setSort] = useState<string>("NAME,asc");

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  async function searchModules(
    page: number,
    itensPerPage: number,
    sort?: string,
    searchQuery?: string,
  ) {
    setItensPerPage(itensPerPage);
    const response = await modulesService.searchFilteredModules(
      addMessages,
      setaLoading,
      navigate,
      page,
      itensPerPage,
      sort,
      searchQuery,
    );
    if (response) {
      setTotalPages(response.totalPages);
      setModules(response.content);
      setCurrentPage(page + 1);
      setTotalRecords(response.totalElements);
    } else {
      setTotalPages(0);
      setModules([]);
      addMessages(["Não houve resposta do serviço de modulos"]);
    }
  }

  async function excluIModule(id: number) {
    let url = `/v1/admin/modules/${id}`;
    const dados = {};
    try {
      const response = await sendDelete<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      addMessages(response.messages);
      searchModules(0, itensPerPage, sort);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    searchModules(0, itensPerPage, sort);
  }, []);

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchModules(page - 1, itensPerPage, sort);
  };

  const handleItemsPerPageChange = (itens: number) => {
    searchModules(0, itens, sort);
  };

  function handleClickEditar(module: IModule): void {
    navigate(`/area-logada/admin/edit-module/${module.id}`);
  }

  function handleClickNovo(): void {
    navigate(`/area-logada/admin/new-module`);
  }

  function handleClickExcluir(
    module: IModule,
  ): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (confirm(`Tem certeza que deseja excluir o modulo ${module.name}`)) {
      excluIModule(module.id);
    }
    return;
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    searchModules(currentPage - 1, itensPerPage, sortNovo);
  }

  const columns = [
    { displayName: "Id", columnName: "sm.ID" },
    { displayName: "Nome", columnName: "sm.NAME" },
    { displayName: "Descrição", columnName: "sm.DESCRIPTION" },
    { displayName: "Ações", columnName: null },
  ];

  <TableRow>
    <CustomTableCell>Id</CustomTableCell>
    <CustomTableCell>Nome</CustomTableCell>
    <CustomTableCell>Descrição</CustomTableCell>
    <CustomTableCell>Usuário Criação</CustomTableCell>
    <CustomTableCell>Data Criação</CustomTableCell>
    <CustomTableCell>Usuário Alteração</CustomTableCell>
    <CustomTableCell>Data Alteração</CustomTableCell>
    <CustomTableCell>Ações</CustomTableCell>
  </TableRow>;

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Módulos</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    }
                    onClick={handleOpenImport}
                  >
                    Importar
                  </Button>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Exportar
                  </Button>
                </Stack>
              </Stack>
              <Stack>
                <Button
                  onClick={() => handleClickNovo()}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {modules.map((row, index) => (
                    <CustomStripedTableRow row={row} index={index}>
                      <CustomTableCell>{row.id}</CustomTableCell>
                      <CustomTableCell>{row.name}</CustomTableCell>
                      <CustomTableCell>{row.description}</CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          <EditAcctionButton row={row} handleClickEditar={handleClickEditar} />
                          <DeleteActionButton row={row} handleClickExcluir={handleClickExcluir} />
                        </CustomActionBox>
                      </CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
              </CustomTable>
            </CustomTableContainer>
            <Paginacao
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              totalRecords={totalRecords}
              itensPerPage={itensPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Modules;
