import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import XCircleIcon from "@heroicons/react/24/solid/XCircleIcon";
import { IconButton, SvgIcon } from "@mui/material";

interface CheckedActionButtonProps {
  children?: React.ReactNode;
  row: any;
  handleClickChangeChecked: (row: any) => void;
  isChecked: boolean;
}

export default function CheckedActionButton(props: CheckedActionButtonProps) {
  return (
    <IconButton onClick={() => props.handleClickChangeChecked(props.row)} sx={{ padding: "0" }}>
      {props.isChecked ? (
        <SvgIcon fontSize="small" style={{ color: "#10B981" }}>
          <CheckCircleIcon fontSize="small" />
        </SvgIcon>
      ) : (
        <SvgIcon fontSize="small" style={{ color: "#EF4444" }}>
          <XCircleIcon fontSize="small" />
        </SvgIcon>
      )}
    </IconButton>
  );
}
