import ChatBubbleOvalLeftEllipsisIcon from "@heroicons/react/24/solid/ChatBubbleOvalLeftEllipsisIcon";
import { Badge, IconButton, SvgIcon, Tooltip } from "@mui/material";

interface WppActionButtonProps {
  children?: React.ReactNode;
  handleClickWhatsApp: (row: any) => void;
  handleClickWhatsAppDisabled: (statusWpp: string, qtdChats: number) => void;
  row: any;
  statusWpp: string;
}

const iconStyles = {
  fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
  marginRight: "8px", // Ajuste o espaçamento entre os ícones
};
const iconStylesDisabled = {
  fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
  marginRight: "8px", // Ajuste o espaçamento entre os ícones
  opacity: 0.1,
};

export default function WppActionButton(props: WppActionButtonProps) {
  return (
    <Badge
      color={props.statusWpp === "Conectada" ? "success" : "error"}
      variant="dot"
      overlap="circular"
    >
      <Tooltip
        title={
          props.statusWpp === "Conectada" && props.row.qtdClientes > 0
            ? `Enviar WhatsApp para ${props.row.qtdClientes} clientes`
            : `Envio WhatsApp Desabilitado. Conta Wpp ${props.statusWpp} (qtd clientes: ${props.row.qtdClientes})`
        }
      >
        <IconButton
          onClick={
            props.statusWpp === "Conectada" && props.row.qtdClientes > 0
              ? () => props.handleClickWhatsApp(props.row)
              : () => props.handleClickWhatsAppDisabled(props.statusWpp, props.row.qtdChats)
          }
          style={{ padding: "0px 4px 0px 0px" }}
        >
          <SvgIcon fontSize="small">
            <ChatBubbleOvalLeftEllipsisIcon
              aria-label="Enviar WhatsApp"
              style={
                props.statusWpp === "Conectada" && props.row.qtdClientes > 0
                  ? iconStyles
                  : iconStylesDisabled
              }
            />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </Badge>
  );
}
