import React, { useContext, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate } from "react-router-dom";
import RolesCheckboxGrid from "../../../../../components/elements/grids/RolesCheckboxGrid";
import Titulo from "../../../../../components/elements/Titulo";
import http from "../../../../services/http";
import { IMenu } from "../../../../types/Adm/Menu/IMenu";
import { IMenuNewData } from "../../../../types/Adm/Menu/IMenuNewData";
import { IRole } from "../../../../types/Adm/Role/IRole";
import { IResponse } from "../../../../types/Generic/IResponse";
import "./InclusaoContas.css";
import { IUserNewData } from "../../../../types/Adm/User/IUserNewData";
import AccountsCheckboxGrid from "../../../../../components/elements/grids/AccountsCheckboxGrid";
import { IAccount } from "../../../../types/Adm/Account/IAccount";
import { IAccountNewData } from "../../../../types/Adm/Account/IAccountNewData";
import { IModule } from "../../../../types/Adm/Module/IModule";
import { IPlan } from "../../../../types/Adm/Plan/IPlan";
import ModulesCheckboxGrid from "../../../../../components/elements/grids/ModulesCheckboxGrid";
import PlanosCheckboxGrid from "../../../../../components/elements/grids/PlanosCheckboxGrid";
import { sendPost } from "../../../../utils/httpUtils";
import { GlobalContext } from "../../../../context/GlobalContext";

const InclusaoContas = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [conta, setConta] = useState<IAccountNewData>({
    name: "",
    fantasyName: "",
    cnpj: "",
    active: true,
    bloqued: false,
    modules: [],
    planos: [],
  } as IAccountNewData);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/admin/contas");
  }

  async function handleClickSalvar() {
    let url = `v1/admin/accounts/new`;
    const dados = conta;
    try {
      const response = await sendPost<IAccount>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/admin/contas");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
  ) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;

    let valor = type === "checkbox" ? target.checked : target.value;

    setConta((prevConta) => ({ ...prevConta, [name]: valor }));
  };

  const handleModuloChange = (event: React.ChangeEvent<HTMLInputElement>, module: IModule) => {
    const target = event.target as HTMLInputElement;
    const valor = target.checked;

    if (valor) {
      setConta((prevConta) => ({ ...prevConta, modules: [...prevConta.modules, module] }));
    } else {
      setConta((prevConta) => ({
        ...prevConta,
        modules: prevConta.modules.filter((module1) => module1.id !== module.id),
      }));
    }
  };

  const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>, plano: IPlan) => {
    const target = event.target as HTMLInputElement;
    const valor = target.checked;

    if (valor) {
      setConta((prevConta) => ({ ...prevConta, planos: [...prevConta.planos, plano] }));
    } else {
      setConta((prevConta) => ({
        ...prevConta,
        planos: prevConta.planos.filter((plane) => plane.id !== plano.id),
      }));
    }
  };

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Incluir Conta</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Nome"
                  value={conta.name}
                  name="name"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nome Fantasia"
                  value={conta.fantasyName}
                  name="fantasyName"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="CNPJ"
                  value={conta.cnpj}
                  name="cnpj"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={conta.active || false}
                      onChange={handleChange}
                      name="active"
                      value={conta.active}
                    />
                  }
                  label="Ativa"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={conta.bloqued || false}
                      onChange={handleChange}
                      name="bloqued"
                      value={conta.bloqued}
                    />
                  }
                  label="Bloqueada"
                />
              </Grid>
              <ModulesCheckboxGrid
                selectedModules={conta.modules}
                handleChange={handleModuloChange}
              />
              <PlanosCheckboxGrid
                planosSelecionados={conta.planos}
                handleChange={handlePlanChange}
              />

              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default InclusaoContas;
