import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  SvgIcon,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useState } from "react";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate } from "react-router-dom";
import NumericFormatCustom from "../../../../../components/elements/NumericFormatCustom";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import recurrencyService from "../../../../services/admin/RecurrencyService";
import { ICapCategory } from "../../../../types/Cap/Category/ICapCategory";
import { ICapRecurrency } from "../../../../types/Cap/Recurrency/ICapRecurrency";
import { ICapRecurrencyNewData } from "../../../../types/Cap/Recurrency/ICapRecurrencyNewData";
import { ICapSuplier } from "../../../../types/Cap/Suplier/ICapSuplier";
import { IBankAccount } from "../../../../types/Ceb/IBankAccount";
import { IPaginatedList } from "../../../../types/Generic/IPaginatedList";
import { ISimpleListStringString } from "../../../../types/Generic/ISimpleListStringString";
import { sendPost } from "../../../../utils/httpUtils";
import "./NewRecurrency.css";
import { display } from "@mui/system";

const NewCapRecurrency = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  //Inicia todas as propriedadese do objeto como string vazia
  const [recurrency, setRecurrency] = useState<ICapRecurrencyNewData>({
    repeatEvery: 1,
    timeMeasure: {id:"MONTH", label:"Mês(es)"},
    projectionTime: 12,
    suplierId: 0,
    bankAccountId: 0,
    billDescription: "",
    firstBillDate: new Date(),
    firstDueDate: new Date(),
    categoryId: null,
    vlrPagar: 0,
  } as ICapRecurrencyNewData);

  const [suplierList, setSuplierList] = useState<ICapSuplier[]>([] as ICapSuplier[]);

  const [timeMeasureList, setTimeMeasureList] = useState<ISimpleListStringString[]>([] as ISimpleListStringString[]);

  const [bankAccountsList, setBankAccountsList] = useState<IBankAccount[]>([] as IBankAccount[]);

  const [categoriesList, setCategoriesList] = useState<ICapCategory[]>([] as ICapCategory[]);

  const { selectedAccount, accounts } = useContext(GlobalContext);

  const [temModuloBankAccount, setTemModuloBankAccount] = useState<boolean>(false);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/cap/recurrency");
  }

  async function searchSupliers(suplierName: string) {
    let url = `v1/cap/supliers/list?size=10&page=0&sort=NAME,asc`;
    const dados = { suplierName: suplierName };
    try {
      const response = await sendPost<IPaginatedList<ICapSuplier>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setSuplierList(response.content);
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchPossibleTimeMeasureList() {
    const response = await recurrencyService.searchPossibleTimeMeasureList(
      addMessages,
      setaLoading,
      navigate,
    );
    if (response) {
      setTimeMeasureList(response);
    } else {
      addMessages(["Não houve resposta do serviço de recorrências"]);
    }
  }

  function validateForm() {
    //Aplicar trim em todos os campos

    if (recurrency.repeatEvery <= 0) {
      addMessages(["Repetir a cada é obrigatório"]);
      return false;
    }

    if (recurrency.repeatEvery > 11) {
      addMessages(["Repetir a cada deve ser menor ou igual a 11"]);
      return false;
    }

    if (recurrency.projectionTime <= 0) {
      addMessages(["Projeção de tempo é obrigatório"]);
      return false;
    }

    if (recurrency.projectionTime > 120) {
      addMessages(["Projeção de tempo deve ser menor ou igual a 120"]);
      return false;
    }

    if (recurrency.timeMeasure.id === "") {
      addMessages(["Unidade de tempo é obrigatório"]);
      return false;
    }

    //Verificar se timeMeasure está na lista de timeMeasureList
    if (!timeMeasureList.some((timeMeasure) => timeMeasure.id === recurrency.timeMeasure.id)) {
      addMessages(["Unidade de tempo inválida"]);
      return false;
    }

    if (recurrency.suplierId <= 0) {
      addMessages(["Fornecedor é obrigatório"]);
      return false;
    }

    if (recurrency.firstBillDate === undefined) {
      addMessages(["Primeira Emissão é obrigatória"]);
      return false;
    }

    if (recurrency.firstDueDate === undefined) {
      addMessages(["Primeiro Vencimento é obrigatório"]);
      return false;
    }
    
    if ((recurrency.vlrPagar && recurrency.vlrPagar <= 0) || !recurrency.vlrPagar) {
      addMessages(["Valor a Pagar deve ser maior que zero"]);
      return false;
    }

    return true;
  }
  async function handleClickSave() {
    if (!validateForm()) {
      return;
    }

    let url = `v1/cap/recurrency/new`;
                  
    const dados = {
      repeatEvery: recurrency.repeatEvery,
      timeMeasure: recurrency.timeMeasure.id,
      projectionTime: recurrency.projectionTime,
      suplierId: recurrency.suplierId,
      bankAccountId: recurrency.bankAccountId,
      billDescription: recurrency.billDescription,
      firstBillDate: recurrency.firstBillDate,
      firstDueDate: recurrency.firstDueDate,
      categoryId: recurrency.categoryId,
      vlrPagar: recurrency.vlrPagar,
    };
    try {
      const response = await sendPost<ICapRecurrency>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/cap/recurrency");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchSuplier(suplierName: string) {
    let url = `v1/cap/supliers/list?size=10&page=0&sort=NAME,asc`;
    const dados = { suplierName: suplierName };
    try {
      const response = await sendPost<IPaginatedList<ICapSuplier>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setSuplierList(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchCategories(categoryName: string) {
    let url = `v1/cap/category/list?size=10&page=0&sort=NAME,asc`;
    const dados = { name: categoryName };
    try {
      const response = await sendPost<IPaginatedList<ICapCategory>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setCategoriesList(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function searchBankAccount(bankAccountName: string) {
    let url = `v1/ceb/bank-account/list?size=10&page=0&sort=NAME,asc`;
    const dados = { name: bankAccountName };
    try {
      const response = await sendPost<IPaginatedList<IBankAccount>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setBankAccountsList(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setRecurrency((prevRecurrency) => ({ ...prevRecurrency, [name]: valor }));
  };

  useEffect(() => {
    searchPossibleTimeMeasureList();
    searchSuplier("");
    searchCategories("");
    searchBankAccount("");
    
    const account = accounts.find((account) => account.accountId.toString() === selectedAccount);
    setTemModuloBankAccount(false);
    if (account) {
      const modulesToCheck = ["Caixas e Bancos"];
      const modules = account.modules;
      console.log(modules);
      if (modules.some((module) => modulesToCheck.includes(module.name))) {
        setTemModuloBankAccount(true);
      } else {
        console.log(`Módulo bankAccount não encontrado com o selectedAccount ${selectedAccount}`);
      }
    } else {
      console.log(`Nenhuma conta encontrada com o selectedAccount ${selectedAccount}`);
    }
  }, []);

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Incluir Recorrência de Conta à Pagar</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{display:"flex"}}>
                <NumericFormatCustom
                  prefix=""
                  decimalScale={0}
                  thousandSeparator=""
                  label="Repetir a cada"
                  value={recurrency.repeatEvery}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setRecurrency((prevRecurrency) => ({ ...prevRecurrency, repeatEvery: Number(values.target.value) }))
                  }
                  name="repeatEvery"
                  style={{ width: 130 }}
                />&nbsp;&nbsp;<Autocomplete 
                  disablePortal
                  id="combo-box-timeMeasure"
                  options={timeMeasureList}
                  disableClearable={true}
                  getOptionLabel={(option) => option.label} // Use o nome da unidade como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 130 }}
                  value={recurrency.timeMeasure}
                  onChange={(event, newValue) => {
                    const selectedLabel = timeMeasureList.find(item => item.id === newValue.id)?.label || "";
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      timeMeasure: {id: newValue.id, label: selectedLabel},
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Unidade de Tempo" required />}
                />&nbsp;&nbsp;<NumericFormatCustom
                prefix=""
                sufix={" " + timeMeasureList.find(item => item.id === recurrency.timeMeasure.id)?.label}
                decimalScale={0}
                thousandSeparator=""
                label="Tempo de Projeção"
                value={recurrency.projectionTime}
                onChange={(values: { target: { name: string; value: string } }) =>
                  setRecurrency((prevRecurrency) => ({
                    ...prevRecurrency,
                    projectionTime: Number(values.target.value),
                  }))
                }
                name="projectionTime"
                style={{ width: 130 }}
              />

              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-suplier"
                  options={suplierList}
                  getOptionLabel={(option) => option.peopleCpfCnpj + " - " + option.peopleName} // Use o nome do fornecedor como label
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      suplierId: newValue ? newValue.id : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Fornecedor" required />}
                  onInputChange={(event, newInputValue) => {
                    searchSupliers(newInputValue);
                  }}
                />
              </Grid>
              {temModuloBankAccount && (
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-bankAccount"
                    options={bankAccountsList}
                    getOptionLabel={(option) => option.name} // Use o nome do cliente como label
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => {
                      setRecurrency((prevRecurrency) => ({
                        ...prevRecurrency,
                        bankAccountId: newValue ? newValue.id : 0,
                      }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Conta Bancária" />}
                    onInputChange={(event, newInputValue) => {
                      searchBankAccount(newInputValue);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Descrição"
                  name="billDescription"
                  onChange={handleChange}
                  value={recurrency.billDescription}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Primeira Data emissão"
                  value={recurrency.firstBillDate}
                  onChange={(newValue: Date | null) => {
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      firstBillDate: newValue ? newValue : new Date(),
                    }));
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 245 }} name="firstBllDate" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Primeiro Vencimento"
                  value={recurrency.firstDueDate}
                  onChange={(newValue: Date | null) => {
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      firstDueDate: newValue ? newValue : new Date(),
                    }));
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 245 }} name="firstDueDate" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-wallet"
                  options={categoriesList}
                  getOptionLabel={(option) =>
                    (option.upperCategoryName ? option.upperCategoryName + " -> " : "") +
                    option.name
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: 300 }}
                  onChange={(event, newValue) => {
                    setRecurrency((prevRecurrency) => ({
                      ...prevRecurrency,
                      categoryId: newValue ? newValue.id : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Categoria" required />}
                  onInputChange={(event, newInputValue) => {
                    searchCategories(newInputValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <NumericFormatCustom
                  label="Valor a Pagar"
                  value={recurrency.vlrPagar}
                  onChange={(values: { target: { name: string; value: string } }) =>
                    setRecurrency((prevRecurrency) => ({ ...prevRecurrency, vlrPagar: Number(values.target.value) }))
                  }
                  name="vlrPagar"
                  fullWidth
                />
              </Grid>

              <Grid item xs={2}>
                <Button
                  onClick={handleClickSave}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default NewCapRecurrency;
