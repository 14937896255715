import React, { useState } from "react";
import { Box, SvgIcon, TableBody, TableHead, TableRow } from "@mui/material";
import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";
import ChevronUpICon from "@heroicons/react/24/solid/ChevronUpIcon";
import CustomTableCell from "../CustomTableCell";
import ChevronDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import ChevronUpDownIcon from "@heroicons/react/24/solid/ChevronUpDownIcon";

interface Column {
  displayName: string; // Nome da coluna a ser exibido na tabela
  columnName: string | null; // Nome da coluna no banco de dados
}

interface TableHeaderProps {
  initialSortedField: string;
  initialSortedDirection: "asc" | "desc";
  columns: Column[];
  handleSortSearch: (field: string) => void;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  initialSortedField,
  initialSortedDirection,
  columns,
  handleSortSearch,
}) => {
  const [sortedField, setSortedField] = useState<string | null>(initialSortedField);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">(initialSortedDirection);

  function handleSortChange(sortColumn: string | null) {
    let colunaAnterior = sortedField;
    let direcaoAnterior = sortDirection;

    let colunaNova = colunaAnterior;
    let direcaoNova = direcaoAnterior;
    if (sortColumn === colunaAnterior) {
      if (direcaoAnterior === "asc") {
        colunaNova = sortColumn;
        direcaoNova = "desc";
      } else {
        colunaNova = sortColumn;
        direcaoNova = "asc";
      }
    } else {
      colunaNova = sortColumn;
      direcaoNova = "asc";
    }

    setSortedField(colunaNova);
    setSortDirection(direcaoNova);
    handleSortSearch(colunaNova + "," + direcaoNova);
  }

  return (
    <TableHead>
      <TableRow key={0}>
        {columns.map((column) =>
          column.columnName === null ? (
            <CustomTableCell
              style={{ backgroundColor: "#eee", padding: "0.3rem 0rem 0.3rem 0.1rem" }}
            >
              <Box display="flex" alignItems="center">
                {column.displayName}
              </Box>
            </CustomTableCell>
          ) : (
            <CustomTableCell
              style={{
                cursor: "pointer",
                backgroundColor: "#eee",
                padding: "0.3rem 0rem 0.3rem 0.1rem",
              }}
              onClick={() => handleSortChange(column.columnName)}
            >
              <Box display="flex" alignItems="center">
                {column.displayName}
                {sortedField === column.columnName && sortDirection === "asc" ? (
                  <SvgIcon style={{ fontSize: "8px", padding: "0" }}>
                    <ChevronDownIcon />
                  </SvgIcon>
                ) : sortedField === column.columnName && sortDirection === "desc" ? (
                  <SvgIcon style={{ fontSize: "8px", padding: "0" }}>
                    <ChevronUpICon />
                  </SvgIcon>
                ) : (
                  <SvgIcon style={{ fontSize: "8px", padding: "0" }}>
                    <ChevronUpDownIcon />
                  </SvgIcon>
                )}
              </Box>
            </CustomTableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
