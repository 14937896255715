import React, { useContext, useEffect, useState } from "react";

import { Box, Button, Container, Grid, Stack, SvgIcon, TextField } from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate, useParams } from "react-router-dom";
import Titulo from "../../../../../components/elements/Titulo";
import RolesCheckboxGrid from "../../../../../components/elements/grids/RolesCheckboxGrid";
import { GlobalContext } from "../../../../context/GlobalContext";
import { IModule } from "../../../../types/Adm/Module/IModule";
import { IModuleEditData } from "../../../../types/Adm/Module/IModuleEditData";
import { IRole } from "../../../../types/Adm/Role/IRole";
import { sendGet, sendPost } from "../../../../utils/httpUtils";
import "./EditModule.css";

const EditModule = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const { id } = useParams();
  const moduleId = Number(id);

  const [module, setModule] = useState<IModuleEditData>({
    id: moduleId,
    name: "",
    description: "",
    roles: [],
  } as IModuleEditData);

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/admin/modules");
  }

  async function handleClickSalvar() {
    let url = `v1/admin/modules/edit/${moduleId}`;
    const dados = module;
    try {
      const response = await sendPost<IModule>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/admin/modules");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    papel?: IRole,
  ) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = type === "checkbox" ? target.checked : target.value;

    if (type === "checkbox" && papel) {
      if (valor) {
        setModule((prevModule) => ({ ...prevModule, roles: [...prevModule.roles, papel] }));
      } else {
        setModule((prevModule) => ({
          ...prevModule,
          roles: prevModule.roles.filter((role) => role.id !== papel.id),
        }));
      }
    } else {
      setModule((prevModule) => ({ ...prevModule, [name]: valor }));
    }
  };

  async function searchModule() {
    let url = `v1/admin/modules/${moduleId}`;
    const dados = {};
    try {
      const response = await sendGet<IModule>(url, dados, addMessages, setaLoading, navigate);
      setModule(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    searchModule();
  }, []);

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Editar Módulo</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Nome"
                  value={module.name}
                  name="name"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Descrição"
                  value={module.description}
                  name="description"
                  onChange={(event) => handleChange(event)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <RolesCheckboxGrid papeisSelecionados={module.roles} handleChange={handleChange} />
              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default EditModule;
