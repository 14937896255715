import React, { useCallback, useContext, useEffect, useState } from "react";

import "./Recurrency.css";

import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  TextField,
  TextFieldProps,
  styled,
} from "@mui/material";
import { addMonths, endOfMonth, startOfMonth, subMonths } from "date-fns";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import CustomTableCell from "../../../../components/elements/CustomTableCell";
import Paginacao from "../../../../components/elements/Paginacao";
import TableHeader from "../../../../components/elements/TableHeader";
import Titulo from "../../../../components/elements/Titulo";
import { GlobalContext } from "../../../context/GlobalContext";
import { ISuccessoMensagensResponse } from "../../../types/Generic/ISuccessoMensagensResponse";
import { sendDelete, sendPost } from "../../../utils/httpUtils";
import { DatePicker } from "@mui/x-date-pickers";
import { formataDateLocal } from "../../../utils/date-utils";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditAcctionButton from "../../../../components/elements/EditActionButton";
import DeleteActionButton from "../../../../components/elements/DeleteActionButton";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import CustomActionBox from "../../../../components/elements/Crud/CustomActionBox";
import { ICapRecurrency } from "../../../types/Cap/Recurrency/ICapRecurrency";
import recurrencyService from "../../../services/admin/RecurrencyService";
import CheckedActionButton from "../../../../components/elements/CheckedActionButto";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CapRecurrency = () => {
  let navigate = useNavigate();

  const { selectedAccount, addMessages, setaLoading } = useContext(GlobalContext);

  const initialDateDefault = startOfMonth(subMonths(new Date(), 1));

  const finalDateDefault = endOfMonth(addMonths(new Date(), 1));

  const [initialDate, setInitialDate] = useState<Date>(initialDateDefault);
  const [finalDate, setFinalDate] = useState<Date>(finalDateDefault);

  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const [inputSearchValue, setInputSearchValue] = useState("");

  const [recurrency, setRecurrency] = useState<ICapRecurrency[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>("r.FIRST_DUE_DATE,desc");

  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [itensPerPage, setItensPerPage] = useState<number>(5);

  const handleItemsPerPageChange = (itens: number) => {
    searchRecurrency(0, itens, sort, initialDate, finalDate);
  };

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  async function searchRecurrency(
    page: number,
    itensPerPage: number,
    sort?: string,
    initialDate?: Date,
    finalDate?: Date,
    searchQuery?: string,
  ) {
    const response = await recurrencyService.searchFilteredCapRecurrencies(
      addMessages,
      setaLoading,
      navigate,
      page,
      itensPerPage,
      sort,
      initialDate,
      finalDate,
      searchQuery,
    );
    if (response) {
      setTotalPages(response.totalPages);
      setRecurrency(response.content);
      setCurrentPage(page + 1);
    } else {
      setTotalPages(0);
      setRecurrency([]);
      addMessages(["Não houve resposta do serviço de recorrências"]);
    }
  }

  async function deleteRecurrency(id: number) {
    let url = `/v1/cap/recurrency/${id}`;
    const dados = {};
    try {
      const response = await sendDelete<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      addMessages(response.messages);
      searchRecurrency(0, itensPerPage, sort, initialDate, finalDate, debouncedSearchValue);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  function handleClickDeleteAll(): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (confirm(`Tem certeza que deseja excluir todas as recorrências?`)) {
      deleteRecurrencyByFilter();
    }
    return;
  }

  async function deleteRecurrencyByFilter() {
    let url = `v1/cap/recurrency/delete-by-filter`;
    const dados = {
      initialDueDate: initialDate,
      finalDueDate: finalDate,
      search: debouncedSearchValue,
    };
    try {
      const response = await sendPost<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      if (response.messages) {
        addMessages(response.messages);
      }
      searchRecurrency(0, itensPerPage, sort, initialDate, finalDate, debouncedSearchValue);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  let jaFez: boolean = false;

  // Função de atualização do estado que será chamada pelo debounce
  const updateDebouncedSearchValue = useCallback(
    debounce((value: string) => {
      setDebouncedSearchValue(value);
      // Aqui você pode chamar sua função de busca ou lógica com value
      if (jaFez) {
        searchRecurrency(currentPage - 1, itensPerPage, sort, initialDate, finalDate, value);
      }
      jaFez = true;

      return;
    }, 1000),
    [],
  ); // 1000 ms de delay

  useEffect(() => {
    searchRecurrency(0, itensPerPage, sort, initialDate, finalDate, debouncedSearchValue);
  }, []);

  useEffect(() => {
    // Atualiza o valor debounced toda vez que o inputValue mudar

    updateDebouncedSearchValue(inputSearchValue);
    return updateDebouncedSearchValue.cancel; // Limpa o timeout anterior toda vez que o valor mudar
  }, [inputSearchValue, updateDebouncedSearchValue]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchRecurrency(page - 1, itensPerPage, sort, initialDate, finalDate, debouncedSearchValue);
  };

  function handleClickEdit(recurrency: ICapRecurrency): void {
    navigate(`/area-logada/cap/edit-recurrency/${recurrency.id}`);
  }

  function handleClickNew(): void {
    navigate(`/area-logada/cap/new-recurrency`);
  }

  function handleClickDelete(
    recurrency: ICapRecurrency,
  ): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (
      confirm(
        `Tem certeza que deseja excluir a recorrência ${recurrency.suplierName} - ${recurrency.firstBillDate} - ${
          recurrency.vlrPagar
        } ${recurrency.billDescription ? " - " + recurrency.billDescription.substring(0, 20) + " ... " : ""}`,
      )
    ) {
      deleteRecurrency(recurrency.id);
    }
    return;
  }

  async function handleClickChangeActive(row: ICapRecurrency): Promise<void> {
    let url = `v1/cap/recurrency/update-active/${row.id}`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<ISuccessoMensagensResponse>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      searchRecurrency(
        currentPage - 1,
        itensPerPage,
        sort,
        initialDate,
        finalDate,
        debouncedSearchValue,
      );
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    searchRecurrency(
      currentPage - 1,
      itensPerPage,
      sortNovo,
      initialDate,
      finalDate,
      debouncedSearchValue,
    );
  }

  function refreshFunction() {
    searchRecurrency(
      currentPage - 1,
      itensPerPage,
      sort,
      initialDate,
      finalDate,
      debouncedSearchValue,
    );
  }

  const getTimeMeasureDescription = (timeMeasure: string, repeatEvery: number) => {
    switch (timeMeasure) {
      case "MONTH":
        if (repeatEvery === 1) return "Mês";
        if (repeatEvery > 1) return "Meses";
        break;
      case "YEAR":
        if (repeatEvery === 1) return "Ano";
        if (repeatEvery > 1) return "Anos";
        break;
      default:
        return timeMeasure;
    }
  };

  const columns = [
    { displayName: "Repete a cada", columnName: "r.REPEAT_EVERY, r.TIME_MEASURE" },
    { displayName: "Número de períodos", columnName: "r.PROJECTION_TIME" },
    { displayName: "Fornecedor", columnName: "p.NAME" },
    { displayName: "Ativo", columnName: "r.ACTIVE" },
    { displayName: "Descrição", columnName: "r.BILL_DESCRIPTION" },
    { displayName: "Primeiro Vencimento", columnName: "r.FIRST_DUE_DATE" },
    { displayName: "Vlr Pagar", columnName: "r.VLR_PAGAR" },
    { displayName: "Ações", columnName: null },
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <Stack spacing={1}>
                <Titulo>Recorrência de Contas à Pagar</Titulo>
                <table>
                  <tr>
                    <td>
                      <Button
                        color="inherit"
                        startIcon={
                          <SvgIcon fontSize="small">
                            <DeleteForeverIcon />
                          </SvgIcon>
                        }
                        onClick={handleClickDeleteAll}
                      >
                        Excluir
                      </Button>
                    </td>
                  </tr>
                </table>
              </Stack>
              <Stack alignItems="center" direction="row" spacing={1}>
                <TextField
                  label="Busca"
                  variant="outlined"
                  value={inputSearchValue}
                  onChange={(e) => setInputSearchValue(e.target.value)}
                  placeholder="Digite para buscar..."
                />
                <DatePicker
                  label="Data inicial"
                  value={initialDate}
                  onChange={(newValue: Date | null) => {
                    setInitialDate(newValue ? newValue : initialDate);
                    searchRecurrency(
                      0,
                      itensPerPage,
                      sort,
                      newValue ? newValue : initialDate,
                      finalDate,
                      debouncedSearchValue,
                    );
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 200 }} />
                  )}
                />
                <DatePicker
                  label="Data final"
                  value={finalDate}
                  onChange={(newValue: Date | null) => {
                    setFinalDate(newValue ? newValue : finalDate);
                    searchRecurrency(
                      0,
                      itensPerPage,
                      sort,
                      initialDate,
                      newValue ? newValue : finalDate,
                      debouncedSearchValue,
                    );
                  }}
                  renderInput={(params: TextFieldProps) => (
                    <TextField {...params} style={{ width: 200 }} />
                  )}
                />
                <Button
                  onClick={() => handleClickNew()}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {recurrency.map((row, index) => (
                    <CustomStripedTableRow row={row} index={index}>
                      <CustomTableCell>
                        <CustomActionBox>
                          {row.repeatEvery}{" "}
                          {getTimeMeasureDescription(row.timeMeasure, row.repeatEvery)}
                        </CustomActionBox>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>{row.projectionTime}</CustomActionBox>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          {row.suplierCpfCnpj + " - " + row.suplierName}
                        </CustomActionBox>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CheckedActionButton
                          row={row}
                          isChecked={row.active}
                          handleClickChangeChecked={handleClickChangeActive}
                        />
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>{row.billDescription}</CustomActionBox>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          {formataDateLocal(row.firstDueDate + "T00:00:00-03:00")}
                        </CustomActionBox>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          {`R$ ${row.vlrPagar.toLocaleString("pt-BR")}`}
                        </CustomActionBox>
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          <EditAcctionButton row={row} handleClickEditar={handleClickEdit} />
                          <DeleteActionButton row={row} handleClickExcluir={handleClickDelete} />
                        </CustomActionBox>
                      </CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
              </CustomTable>
            </CustomTableContainer>
            <Paginacao
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              totalRecords={totalRecords}
              itensPerPage={itensPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default CapRecurrency;
