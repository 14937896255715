import { Backdrop, CircularProgress } from "@mui/material";
import { useContext } from "react";
import { GlobalContext } from "../../../core/context/GlobalContext";

const Loading = () => {
  const { loading } = useContext(GlobalContext);
  return (
    <Backdrop
      open={loading}
      style={{
        zIndex: 10000,
        color: "#fff",
        position: "fixed", // Usa 'fixed' para garantir cobertura total da tela
        top: 0, // Posiciona no topo da viewport
        left: 0, // Posiciona à esquerda da viewport
        width: "100vw", // Largura igual à viewport
        height: "100vh", // Altura igual à viewport
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
