import React, { useContext, useEffect, useState } from "react";

import "./Fechamentos.css";

import ChatBubbleOvalLeftEllipsisIcon from "@heroicons/react/24/solid/ChatBubbleOvalLeftEllipsisIcon";
import DocumentMagnifyingGlassIcon from "@heroicons/react/24/solid/DocumentMagnifyingGlassIcon";
import PencilSquareIcon from "@heroicons/react/24/solid/PencilSquareIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";

import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";

import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import {
  Badge,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import CustomTableCell from "../../../../components/elements/CustomTableCell";
import Paginacao from "../../../../components/elements/Paginacao";
import Titulo from "../../../../components/elements/Titulo";
import { GlobalContext } from "../../../context/GlobalContext";
import { IDownloadFile } from "../../../types/Generic/IDownloadFile";
import { IClosure } from "../../../types/Cre/Closure/IClosure";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { ISuccessoMensagensResponse } from "../../../types/Generic/ISuccessoMensagensResponse";
import { IWallet } from "../../../types/Cre/Wallet/IWallet";
import { formataDateLocal } from "../../../utils/date-utils";
import { sendDelete, sendGet, sendPost } from "../../../utils/httpUtils";

import modulesService from "../../../services/admin/ModulesService";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import TableHeader from "../../../../components/elements/TableHeader";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import DeleteActionButton from "../../../../components/elements/DeleteActionButton";
import DetailsActionButton from "../../../../components/elements/DetailsActionButton";
import WppActionButton from "../../../../components/elements/WppActionButton";
import EditAcctionButton from "../../../../components/elements/EditActionButton";
import CustomActionBox from "../../../../components/elements/Crud/CustomActionBox";
import closureService from "../../../services/admin/ClosureService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  textAlign: "center",
  p: 4,
};

const Fechamentos = () => {
  const diasFechamentoInicial = 60;

  const [dataInicialFechamento, setDataInicialFechamento] = useState<Date>(
    subDays(new Date(), diasFechamentoInicial),
  );
  const [dataFinalFechamento, setDataFinalFechamento] = useState<Date>(new Date());

  let navigate = useNavigate();

  const { addMessages, setaLoading, selectedAccount, modules } = useContext(GlobalContext);

  const [openImport, setOpenImport] = React.useState(false);
  const [openExport, setOpenExport] = React.useState(false);

  const [exportFileType, setExportFileType] = React.useState("E");
  const [exportReportType, setExportReportType] = React.useState("R");

  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const handleOpenExport = () => setOpenExport(true);
  const handleCloseExport = () => setOpenExport(false);

  const [fechamentos, setFechamentos] = useState<IClosure[]>([]);

  const [statusWpp, setstatusWpp] = useState<string>("Desconectada");

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [selectedFile, setSelectedFile] = useState<File>();

  const [selectedWallet, setSelectedWallet] = useState<string>(" ");

  const [wallets, setWallets] = useState<IWallet[]>([]);

  const [sort, setSort] = useState<string>("clo.CLOSURE_DATE,desc");

  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [itensPerPage, setItensPerPage] = useState<number>(5);

  const handleItemsPerPageChange = (itens: number) => {
    searchClosure(0, itens, sort, selectedWallet, dataInicialFechamento, dataFinalFechamento);
  };

  const handleFileImport = async () => {
    if (selectedFile === undefined && !selectedFile) {
      alert("Favor selecionar um arquivo para enviar");
      return;
    }
    let url = `v1/closure/import`;
    const dados = new FormData();
    dados.append("file", selectedFile);

    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await sendPost<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
        headers,
      );
      addMessages(response.messages);
      deselectFile();
      handleCloseImport();
      searchClosure(
        0,
        itensPerPage,
        sort,
        selectedWallet,
        dataInicialFechamento,
        dataFinalFechamento,
      );
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  };

  const handleFileExport = async () => {
    let url = `v1/closure/export`;
    const dados = {
      initialClosureDate: dataInicialFechamento,
      finalClosureDate: dataFinalFechamento,
      walletId: selectedWallet,
      fileType: exportFileType,
      reportType: exportReportType,
    };

    try {
      const response = await sendPost<IDownloadFile>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      const byteCharacters = atob(response.fileBytes);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: response.fileType });
      const urlLink = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = urlLink;
      link.download = response.fileName;
      link.click();
      //addMessages(response.messages);
      //deselectFile();
      handleCloseExport();
      //consultaFechamentos(0, selectedWallet, dataInicialFechamento, dataFinalFechamento);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  };

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  async function searchClosure(
    page: number,
    itensPerPage: number,
    sort?: string,
    walletId?: string,
    initialDate?: Date,
    finalDate?: Date,
    searchQuery?: string,
  ) {
    setItensPerPage(itensPerPage);
    const response = await closureService.searchFilteredClosure(
      addMessages,
      setaLoading,
      navigate,
      page,
      itensPerPage,
      sort,
      walletId,
      initialDate,
      finalDate,
      searchQuery,
    );
    if (response) {
      setTotalPages(response.totalPages);
      setFechamentos(response.content);
      setCurrentPage(page + 1);
      setTotalRecords(response.totalElements);
    } else {
      setTotalPages(0);
      setFechamentos([]);
      addMessages(["Não houve resposta do serviço de fechamentos"]);
    }
  }

  async function consultaCarteiras(page: number) {
    let url = `v1/wallet/list?size=1000&page=${page}`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<IWallet>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setWallets(response.content);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function consultaStatusWpp() {
    let url = `v1/config/wpp/status`;
    const dados = {};
    try {
      const response = await sendGet<string>(url, dados, addMessages, setaLoading, navigate);
      setstatusWpp(response);
      console.log("statusWpp:");
      console.log(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function enviaMensagens(id: number) {
    let url = `/v1/wpp/closure/send/${id}`;
    const dados = {};
    try {
      const response = await sendGet<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      addMessages(response.messages);
      searchClosure(
        0,
        itensPerPage,
        sort,
        selectedWallet,
        dataInicialFechamento,
        dataFinalFechamento,
      );
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function excluiFechamento(id: number) {
    let url = `/v1/closure/${id}`;
    const dados = {};
    try {
      const response = await sendDelete<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      addMessages(response.messages);
      searchClosure(
        0,
        itensPerPage,
        sort,
        selectedWallet,
        dataInicialFechamento,
        dataFinalFechamento,
      );
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    searchClosure(
      0,
      itensPerPage,
      sort,
      selectedWallet,
      dataInicialFechamento,
      dataFinalFechamento,
    );
    consultaCarteiras(0);
    consultaStatusWpp();
  }, []);

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchClosure(
      page - 1,
      itensPerPage,
      sort,
      selectedWallet,
      dataInicialFechamento,
      dataFinalFechamento,
    );
  };

  function handleEdit(row: IClosure): void {
    throw new Error("Function not implemented.");
  }

  function handleDelete(row: IClosure): void {
    throw new Error("Function not implemented.");
  }

  function handleClickDetails(
    closureId: number,
  ): React.MouseEventHandler<HTMLButtonElement> | undefined {
    navigate(`/area-logada/detalhes-fechamento/${closureId}`);
    return;
  }

  function handleClickExcluir(
    closure: IClosure,
  ): React.MouseEventHandler<HTMLButtonElement> | undefined {
    let qtdDuplicatas = 0;
    for (let i = 0; i < closure.carteirasClientes.length; i++) {
      qtdDuplicatas += closure.carteirasClientes[i].qtdClientes;
    }
    if (
      confirm(
        `Tem certeza que deseja excluir o fechamento do dia ${formataDateLocal(closure.closureDate + "T00:00:00-03:00")} com ${qtdDuplicatas} duplicatas`,
      )
    ) {
      excluiFechamento(closure.id);
    }
    return;
  }

  function handleClickWhatsApp(fechamento: IClosure): void {
    if (confirm(`Tem certeza que deseja enviar WhatsApp para ${fechamento.qtdClientes} clientes`)) {
      enviaMensagens(fechamento.id);
    }
  }

  function handleClickWhatsAppDisabled(statusWpp: String, qtdChats: number): void {
    if (qtdChats <= 0 && statusWpp === "Conectada") {
      alert("Não há chats para enviar mensagens");
    } else {
      alert("WhatsApp desconectado. Favor conectar o WhatsApp para enviar mensagens.");
      navigate(`/area-logada/config`);
    }
  }

  function deselectFile() {
    setSelectedFile(undefined);
  }
  function handleChange(file: File) {
    setSelectedFile(file);
  }

  function formatarNomeArquivo(nomeArquivo: string) {
    // Removendo a extensão
    const nomeSemExtensao = nomeArquivo.split(".").slice(0, -1).join(".");
    const partes = nomeArquivo.split(".");
    const extensao = partes.length > 1 ? partes.pop() : "";

    // Limitando a 10 caracteres e adicionando '...'
    if (nomeArquivo.length > 27) {
      return (
        nomeSemExtensao.substring(0, 16) +
        " ... " +
        nomeSemExtensao.substring(nomeSemExtensao.length - 3) +
        "." +
        extensao
      );
    } else {
      return nomeArquivo;
    }
  }

  const handleChangeWallet = (event: SelectChangeEvent) => {
    setSelectedWallet(event.target.value);
    searchClosure(
      0,
      itensPerPage,
      sort,
      event.target.value,
      dataInicialFechamento,
      dataFinalFechamento,
    );
  };

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    searchClosure(currentPage - 1, itensPerPage, sortNovo);
    console.log(sortNovo);
  }

  const columns = [
    { displayName: "Carteira: Qtd Duplicatas", columnName: null },
    { displayName: "Data Fechamento", columnName: "clo.CLOSURE_DATE" },
    { displayName: "Saldo total", columnName: "clo.SALDO_TOTAL" },
    { displayName: "Ações", columnName: null },
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Fechamentos</Titulo>
                <Stack direction="column">
                  <Stack alignItems="center" direction="row" justifyContent="flex-end" spacing={27}>
                    <Stack direction="row" justifyContent="space-between">
                      <Button
                        color="inherit"
                        startIcon={
                          <SvgIcon fontSize="small">
                            <ArrowUpOnSquareIcon />
                          </SvgIcon>
                        }
                        onClick={selectedAccount === "2" ? handleOpenImport : undefined}
                        disabled={selectedAccount !== "2"}
                      >
                        Importar
                      </Button>
                      {selectedAccount === "2" && (
                        <Modal
                          keepMounted
                          open={openImport}
                          onClose={handleCloseImport}
                          aria-labelledby="keep-mounted-modal-title"
                          aria-describedby="keep-mounted-modal-description"
                        >
                          <Box sx={style}>
                            <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                              Importação de Fechamento
                            </Typography>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              spacing={2}
                              sx={{ mt: 2 }}
                            >
                              <Button
                                component="label"
                                variant="contained"
                                color="inherit"
                                startIcon={
                                  <SvgIcon fontSize="small">
                                    <CloudArrowUpIcon />
                                  </SvgIcon>
                                }
                              >
                                Selecionar arquivo
                                <VisuallyHiddenInput
                                  type="file"
                                  onChange={(e) => {
                                    if (e.target.files && e.target.files.length > 0) {
                                      // Acessar o primeiro arquivo
                                      const file = e.target.files[0];
                                      handleChange(file);
                                    }
                                  }}
                                />
                              </Button>
                              {selectedFile && <>{formatarNomeArquivo(selectedFile.name)}</>}

                              <Button
                                component="label"
                                variant="contained"
                                color="primary"
                                startIcon={
                                  <SvgIcon fontSize="small">
                                    <ArrowRightIcon />
                                  </SvgIcon>
                                }
                                onClick={handleFileImport}
                              >
                                Importar
                              </Button>
                            </Stack>
                          </Box>
                        </Modal>
                      )}
                      <Button
                        color="inherit"
                        startIcon={
                          <SvgIcon fontSize="small">
                            <ArrowDownOnSquareIcon />
                          </SvgIcon>
                        }
                        onClick={handleOpenExport}
                      >
                        Exportar
                      </Button>
                      <Modal
                        keepMounted
                        open={openExport}
                        onClose={handleCloseExport}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                      >
                        <Box sx={style}>
                          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
                            Exportação de Fechamentos
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{ mt: 2, textAlign: "left" }}
                          >
                            <FormControl>
                              <FormLabel id="radio-buttons-group-file-type-label">
                                Tipo Arquivo
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="radio-buttons-group-file-type-label"
                                defaultValue={exportFileType}
                                name="exportFileType"
                                onChange={(event) => setExportFileType(event.target.value)}
                              >
                                <FormControlLabel value="P" control={<Radio />} label="PDF" />
                                <FormControlLabel value="E" control={<Radio />} label="Excel" />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <FormLabel id="radio-buttons-group-file-type-label">
                                Tipo Relatório
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="radio-buttons-group-file-type-label"
                                defaultValue={exportReportType}
                                name="exportReportType"
                                onChange={(event) => setExportReportType(event.target.value)}
                              >
                                <FormControlLabel value="D" control={<Radio />} label="Detalhado" />
                                <FormControlLabel value="R" control={<Radio />} label="Resumido" />
                              </RadioGroup>
                            </FormControl>

                            <Button
                              component="label"
                              variant="contained"
                              color="primary"
                              startIcon={
                                <SvgIcon fontSize="small">
                                  <ArrowRightIcon />
                                </SvgIcon>
                              }
                              onClick={handleFileExport}
                            >
                              Exportar
                            </Button>
                          </Stack>
                        </Box>
                      </Modal>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={3}>
                      <Select
                        labelId="carteira-simple-select-label"
                        id="carteira-simple-select"
                        value={selectedWallet}
                        label="Carteira"
                        name="selectedWallet"
                        onChange={handleChangeWallet}
                      >
                        <MenuItem key={0} value=" " selected={true}>
                          Todas as Carteiras
                        </MenuItem>
                        {wallets.map((row) => (
                          <MenuItem key={row.id} value={row.id}>
                            {row.walletNumber} - {row.walletName}
                          </MenuItem>
                        ))}
                      </Select>
                      <DatePicker
                        label="Data inicial"
                        value={dataInicialFechamento}
                        onChange={(newValue: Date | null) => {
                          setDataInicialFechamento(
                            newValue ? newValue : subDays(new Date(), diasFechamentoInicial),
                          );
                          searchClosure(
                            0,
                            itensPerPage,
                            sort,
                            selectedWallet,
                            newValue ? newValue : subDays(new Date(), diasFechamentoInicial),
                            dataFinalFechamento,
                          );
                        }}
                        renderInput={(params: TextFieldProps) => (
                          <TextField {...params} style={{ width: 140 }} />
                        )}
                      />
                      <DatePicker
                        label="Data final"
                        value={dataFinalFechamento}
                        onChange={(newValue: Date | null) => {
                          setDataFinalFechamento(newValue ? newValue : new Date());
                          searchClosure(
                            0,
                            itensPerPage,
                            sort,
                            selectedWallet,
                            dataInicialFechamento,
                            newValue ? newValue : new Date(),
                          );
                        }}
                        renderInput={(params: TextFieldProps) => (
                          <TextField {...params} style={{ width: 140 }} />
                        )}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack>
                <Button
                  disabled
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {fechamentos.map((row, index) => (
                    <CustomStripedTableRow row={row.id} index={index}>
                      <CustomTableCell>
                        {row.carteirasClientes.map((row1) => (
                          <div key={Number(row1.walletNumber)}>
                            {row1.walletNumber} - {row1.walletName}: {row1?.qtdClientes} <br />
                          </div>
                        ))}
                      </CustomTableCell>
                      <CustomTableCell>
                        {formataDateLocal(row.closureDate + "T00:00:00-03:00")}
                      </CustomTableCell>
                      <CustomTableCell>{`R$ ${row.saldoTotal.toLocaleString("pt-BR")}`}</CustomTableCell>
                      <CustomTableCell>
                        <CustomActionBox>
                          <DetailsActionButton row={row} handleClickDetails={handleClickDetails} />
                          <EditAcctionButton
                            row={row}
                            handleClickEditar={handleEdit}
                            disabled={true}
                          />
                          <DeleteActionButton row={row} handleClickExcluir={handleClickExcluir} />
                          {/* {modulesService.checkModules(["WhatsApp"], modules) && (
                        <Badge
                          color={statusWpp === "Conectada" ? "success" : "error"}
                          variant="dot"
                          overlap="circular">
                          <Tooltip title={(statusWpp === "Conectada" && row.qtdClientes > 0) ? `Enviar WhatsApp para ${row.qtdClientes} clientes` : `Envio WhatsApp Desabilitado. Conta Wpp ${statusWpp} (qtd clientes: ${row.qtdClientes})`}>
                            <IconButton onClick={(statusWpp === "Conectada" && row.qtdClientes > 0) ? () => handleClickWhatsApp(row) : () => handleClickWhatsAppDisabled(statusWpp, row.qtdClientes)}>
                              <SvgIcon fontSize="small">
                                <ChatBubbleOvalLeftEllipsisIcon aria-label="Enviar WhatsApp" />
                              </SvgIcon>
                            </IconButton>
                          </Tooltip>
                        </Badge>)} */}
                          <WppActionButton
                            row={row}
                            handleClickWhatsApp={handleClickWhatsApp}
                            handleClickWhatsAppDisabled={handleClickWhatsAppDisabled}
                            statusWpp={statusWpp}
                          />
                        </CustomActionBox>
                      </CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
                <Paginacao
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  totalRecords={totalRecords}
                  itensPerPage={itensPerPage}
                  handleItemsPerPageChange={handleItemsPerPageChange}
                />
              </CustomTable>
            </CustomTableContainer>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Fechamentos;
