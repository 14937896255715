import { Link, Typography } from "@mui/material";

import SystemVersion from "../../elements/SystemVersion";
import "./Rodape.css";

const Rodape = () => {
  function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {"Copyright © "}
        <Link color="inherit" href="https://inovacommerce.com.br/">
          Geckel Tecnologia LTDA
        </Link>{" "}
        {new Date().getFullYear()}
        {". "}
      </Typography>
    );
  }
  return (
    <footer className="rodape">
      <Copyright sx={{ mt: 8, mb: 4 }} />
      <SystemVersion sx={{ mt: 8, mb: 4 }} />
    </footer>
  );
};

export default Rodape;
