import { Checkbox, FormControlLabel, Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { IRole } from "../../../../core/types/Adm/Role/IRole";
import rolesService from "../../../../core/services/admin/RolesService";
import { IAccount } from "../../../../core/types/Adm/Account/IAccount";
import contasService from "../../../../core/services/admin/ContasService";
import { IModule } from "../../../../core/types/Adm/Module/IModule";
import modulesService from "../../../../core/services/admin/ModulesService";
import { GlobalContext } from "../../../../core/context/GlobalContext";
import { useNavigate } from "react-router-dom";

interface Props {
  selectedModules: IModule[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, modulo: IModule) => void;
}

const ModulesCheckboxGrid: React.FC<Props> = ({
  selectedModules: selectedModules,
  handleChange,
}) => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  let navigate = useNavigate();

  const [modules, setModules] = useState<IModule[]>([]);

  useEffect(() => {
    modulesService
      .searchAllModules(addMessages, setaLoading, navigate)
      .then((modules) => setModules(modules));
  }, []);

  return (
    <Grid item xs={12} style={{ padding: "20px" }}>
      <Paper elevation={5} style={{ padding: "20px" }}>
        <Typography variant="h6">Módulos</Typography>
        <Grid container spacing={3}>
          {modules.map((module, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedModules?.some(
                      (module1: { id: number }) => module1.id === module.id,
                    )}
                    onChange={(event) => handleChange(event, module)}
                    name="modules[]"
                    value={module}
                  />
                }
                label={module.name}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};
export default ModulesCheckboxGrid;
