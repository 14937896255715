import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { IResponse } from "../../../types/Generic/IResponse";

import Titulo from "../../../../components/elements/Titulo";

import http from "../../../services/http";
import "./Conta.css";

import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import DocumentMagnifyingGlassIcon from "@heroicons/react/24/solid/DocumentMagnifyingGlassIcon";
import DocumentTextIcon from "@heroicons/react/24/solid/DocumentTextIcon";
import QrCodeIcon from "@heroicons/react/24/solid/QrCodeIcon";
import CustomTableCell from "../../../../components/elements/CustomTableCell";
import Paginacao from "../../../../components/elements/Paginacao";
import Subtitulo from "../../../../components/elements/Subtitulo";
import { GlobalContext } from "../../../context/GlobalContext";
import rolesService from "../../../services/admin/RolesService";
import { IAccount } from "../../../types/Adm/Account/IAccount";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { ISysBill } from "../../../types/Adm/Bill/ISysBill";
import { formataDateLocal } from "../../../utils/date-utils";
import { sendGet, sendPost } from "../../../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import { display } from "@mui/system";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import TableHeader from "../../../../components/elements/TableHeader";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import BoletoActionButton from "../../../../components/elements/BoletoActionButton";
import NotaFiscalActionButton from "../../../../components/elements/NotaFiscalActionButton";
import DetailsActionButton from "../../../../components/elements/DetailsActionButton";
import CustomActionBox from "../../../../components/elements/Crud/CustomActionBox";
import { IDownloadFile } from "../../../types/Generic/IDownloadFile";

const Conta = () => {
  const { roles, addMessages, setaLoading } = useContext(GlobalContext);

  let navigate = useNavigate();

  const [conta, setConta] = useState<IAccount | null>(null);
  const [faturas, setFaturas] = useState<ISysBill[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>("sb.INITIAL_DATE,desc");

  async function consultaConta(id: number) {
    let url = `/v1/admin/accounts/${id}`;
    const dados = {};
    try {
      const response = await sendGet<IAccount>(url, dados, addMessages, setaLoading, navigate);
      setConta(response);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function consultaFaturas(page: number, sort?: string) {
    if (rolesService.verificaRole(["ADMIN", "ADM_ACC", "VIEW_BILL"], roles)) {
      let url = `/v1/admin/bill/list?size=5&page=${page}${sort ? `&sort=${sort}` : ""}`;
      const dados = {};
      try {
        const response = await sendPost<IPaginatedList<ISysBill>>(
          url,
          dados,
          addMessages,
          setaLoading,
          navigate,
        );
        setTotalPages(response.totalPages);
        setFaturas(response.content);
        setCurrentPage(page + 1);
      } catch (error) {
        // Handle the error here if necessary
        console.log(error);
      }
    }
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    consultaFaturas(page - 1, sort);
  };

  useEffect(() => {
    consultaConta(Number(sessionStorage.getItem("currentAccount")));
    consultaFaturas(0, sort);
  }, []);

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  function formatarDescricaoFatura(descFatura: string) {
    descFatura = descFatura.trim();
    // Limitando a 10 caracteres e adicionando '...'
    if (descFatura.length > 23) {
      //console.log("nomeCliente");
      //console.log("nomeCliente.substring(0, 20)");
      //console.log(nomeCliente);
      //console.log(nomeCliente.substring(0, 20));
      //console.log("nomeCliente.length - 3");
      //console.log(nomeCliente.length - 3);
      //console.log("nomeCliente.length");
      //console.log(nomeCliente.length);
      //console.log("nomeCliente.substring(nomeCliente.length - 3, nomeCliente.length)");
      //console.log(nomeCliente.substring(nomeCliente.length - 3, nomeCliente.length));
      return (
        descFatura.substring(0, 20) +
        " ... " +
        descFatura.substring(descFatura.length - 3, descFatura.length)
      );
    } else {
      return descFatura;
    }
  }

  const handleSlipFileDownload = async (row: ISysBill) => {
    let url = `v1/admin/bill/asaas-slip-download/${row.id}`;
    console.log(url);
    const dados = {};

    try {
      const response = await sendGet<IDownloadFile>(url, dados, addMessages, setaLoading, navigate);
      if (response.fileBytes !== null) {
        const byteCharacters = atob(response.fileBytes);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: response.fileType });
        const urlLink = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = urlLink;
        link.download = response.fileName;
        link.click();
      } else {
        addMessages(["Falha no Download do Arquivo"]);
      }
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  };

  const handleInvoiceFileDownload = async (row: ISysBill) => {
    let url = `v1/admin/bill/asaas-invoice-download/${row.id}`;
    const dados = {};

    try {
      const response = await sendGet<IDownloadFile>(url, dados, addMessages, setaLoading, navigate);
      if (response.fileBytes !== null) {
        const byteCharacters = atob(response.fileBytes);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: response.fileType });
        const urlLink = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = urlLink;
        link.download = response.fileName;
        link.click();
      } else {
        addMessages(["Falha no Download do Arquivo"]);
      }
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  };

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    consultaFaturas(currentPage - 1, sortNovo);
  }

  const columns = [
    { displayName: "Data inicial", columnName: "sb.INITIAL_DATE" },
    { displayName: "Data final", columnName: "sb.FINAL_DATE" },
    { displayName: "Valor", columnName: "sb.BILL_VALUE" },
    { displayName: "Vencimento", columnName: "sb.DUE_DATE" },
    { displayName: "Situação", columnName: "sb.BILL_STATUS" },
    { displayName: "Referente à", columnName: "sb.BILL_FOR" },
    { displayName: "Ações", columnName: "" },
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={1}>
            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <Stack>
                  <Titulo>Conta</Titulo>
                </Stack>
              </Stack>

              <Stack direction="column" justifyContent="space-between" spacing={1}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Stack spacing={1} direction="column" justifyContent="space-between">
                    <Stack>
                      <Typography variant="h6" component="div" gutterBottom>
                        Nome
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {conta?.name}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="h6" component="div" gutterBottom>
                        Nome Fantasia
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {conta?.fantasyName}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="h6" component="div" gutterBottom>
                        CNPJ
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {conta?.cnpj}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>
      {rolesService.verificaRole(["ADMIN", "ADM_ACC", "VIEW_BILL"], roles) && (
        <Paper sx={{ p: 4, display: "flex", flexDirection: "column" }}>
          <Box
            component="section"
            sx={{
              flexGrow: 1,
              py: 0,
            }}
          >
            <Container maxWidth="xl">
              <Stack spacing={3}>
                <Stack direction="row" justifyContent="space-between" spacing={4}>
                  <Stack spacing={1}>
                    <Subtitulo>Faturas do sistema</Subtitulo>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <Button
                        disabled
                        color="inherit"
                        startIcon={
                          <SvgIcon fontSize="small">
                            <ArrowDownOnSquareIcon />
                          </SvgIcon>
                        }
                      >
                        Exportar
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
                <CustomTableContainer>
                  <CustomTable>
                    <TableHeader
                      initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                      initialSortedField={sort.split(",")[0]}
                      columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                      handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                    />
                    <CustomTableBody>
                      {faturas.map((row, index) => (
                        <CustomStripedTableRow row={row.id} index={index}>
                          <CustomTableCell>
                            {formataDateLocal(row.initialDate + "T00:00:00-03:00")}
                          </CustomTableCell>
                          <CustomTableCell>
                            {formataDateLocal(row.finalDate + "T00:00:00-03:00")}
                          </CustomTableCell>
                          <CustomTableCell>{`R$ ${row.billValue.toLocaleString(
                            "pt-BR",
                          )}`}</CustomTableCell>
                          <CustomTableCell>
                            {formataDateLocal(row.dueDate + "T00:00:00-03:00")}
                          </CustomTableCell>
                          <CustomTableCell>{row.billStatus}</CustomTableCell>
                          <CustomTableCell>
                            <Tooltip title={row.billFor}>
                              <span>{formatarDescricaoFatura(row.billFor)}</span>
                            </Tooltip>
                          </CustomTableCell>
                          <CustomTableCell>
                            <CustomActionBox>
                              <BoletoActionButton
                                title="Boleto"
                                disabled={!row.hasSlipFileToDownload}
                                row={row}
                                action={handleSlipFileDownload}
                              />
                              <NotaFiscalActionButton
                                title="Nota Fiscal"
                                disabled={!row.hasInvoiceFileToDownload}
                                row={row}
                                action={handleInvoiceFileDownload}
                              />
                            </CustomActionBox>
                          </CustomTableCell>
                        </CustomStripedTableRow>
                      ))}
                    </CustomTableBody>
                    <Paginacao
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                    />
                  </CustomTable>
                </CustomTableContainer>
              </Stack>
            </Container>
          </Box>
        </Paper>
      )}
    </React.Fragment>
  );
};

export default Conta;
