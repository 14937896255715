import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { IMaskInput } from "react-imask";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate } from "react-router-dom";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import { ICep } from "../../../../types/Generic/ICep";
import { ICreClient } from "../../../../types/Cre/Client/ICreClient";
import { ICreClientNewData } from "../../../../types/Cre/Client/ICreClientNewData";
import { IModule } from "../../../../types/Adm/Module/IModule";
import { sendPost, sendSimpleGet } from "../../../../utils/httpUtils";
import "./NewClient.css";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CepMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CepMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00000-000"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const PhoneMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function PhoneMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={[{ mask: "+55 (00) 0000-0000" }, { mask: "+55 (00) 00000-0000" }]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const WhatsAppMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function WhatsAppMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={[{ mask: "+55 (00) 0000-0000" }, { mask: "+55 (00) 00000-0000" }]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const CpfMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CpfMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000.000.000-00"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const CnpjMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CnpjMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00.000.000/000#-00"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const NewClient = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  //Inicia todas as propriedadese do objeto como string vazia
  const [client, setClient] = useState<ICreClientNewData>({
    peopleId: "",
    peopleName: "",
    peopleFantasyName: "",
    peopleCpfCnpj: "",
    peopleEmail: "",
    municipalInscription: "",
    stateInscription: "",
    addressPostalCode: "",
    addressStreet: "",
    addressNumber: "",
    addressComplement: "",
    addressNeighborhood: "",
    addressCity: "",
    addressState: "",
    addressRegion: "",
    whatsapp: "",
    fixedPhone: "",
    peopleInstagram: "",
    clientNumber: "",
    peopleAdditionalEmails: "",
    observations: "",
  } as ICreClientNewData);

  const [peopleType, setPeopleType] = useState("F");

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/clients");
  }

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function validateCpf(cpf: string) {
    if (cpf.length !== 11) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  }

  function validateCnpj(cnpj: string) {
    if (cnpj === "" || cnpj.length !== 14) {
      return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999"
    ) {
      return false;
    }

    // Valida DVs
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    const digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += Number(numbers.charAt(size - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(0))) {
      return false;
    }

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += Number(numbers.charAt(size - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(1))) {
      return false;
    }

    return true;
  }

  function validateForm() {
    //Aplicar trim em todos os campos
    client.peopleName = client.peopleName.trim();
    client.peopleCpfCnpj = client.peopleCpfCnpj.trim();
    client.addressPostalCode = client.addressPostalCode.trim();
    client.addressNumber = client.addressNumber.trim();
    client.peopleEmail = client.peopleEmail.trim();
    client.whatsapp = client.whatsapp.trim();
    client.fixedPhone = client.fixedPhone.trim();
    client.peopleInstagram = client.peopleInstagram.trim();
    client.clientNumber = client.clientNumber.trim();
    client.peopleAdditionalEmails = client.peopleAdditionalEmails.trim();
    client.observations = client.observations.trim();
    if (peopleType === "F") {
      client.peopleFantasyName = client.peopleFantasyName.trim();
      client.municipalInscription = client.municipalInscription.trim();
      client.stateInscription = client.stateInscription.trim();
    } else {
      client.peopleFantasyName = "";
      client.municipalInscription = "";
      client.stateInscription = "";
    }
    //Retirar caracteres não numéricos dos campos de cpf/cnpj, telefone e celular
    client.peopleCpfCnpj = client.peopleCpfCnpj.replace(/\D/g, "");
    client.whatsapp = client.whatsapp.replace(/\D/g, "");
    client.fixedPhone = client.fixedPhone.replace(/\D/g, "");
    client.addressPostalCode = client.addressPostalCode.replace(/\D/g, "");

    if (client.peopleName === "") {
      addMessages(["Nome é obrigatório"]);
      return false;
    }
    if (client.peopleCpfCnpj === "") {
      addMessages(["CPF/CNPJ é obrigatório"]);
      return false;
    } else {
      if (peopleType === "F") {
        if (!validateCpf(client.peopleCpfCnpj)) {
          addMessages(["CPF inválido"]);
          return false;
        }
      } else {
        if (!validateCnpj(client.peopleCpfCnpj)) {
          addMessages(["CNPJ inválido"]);
          return false;
        }
      }
    }
    if (client.addressPostalCode === "") {
      addMessages(["CEP do endereço é obrigatório"]);
      return false;
    }
    if (client.addressNumber === "") {
      addMessages(["Número do endereço é obrigatório"]);
      return false;
    }

    if (client.peopleEmail !== "") {
      if (!validateEmail(client.peopleEmail)) {
        addMessages(["Email inválido"]);
        return false;
      }
    }
    if (client.whatsapp !== "") {
      if (client.whatsapp.length > 13 || client.whatsapp.length < 12) {
        addMessages(["Whatsapp inválido"]);
        return false;
      }
    }
    if (client.fixedPhone !== "") {
      if (client.fixedPhone.length > 13 || client.fixedPhone.length < 12) {
        addMessages(["Telefone inválido"]);
        return false;
      }
    }
    return true;
  }
  async function handleClickSalvar() {
    if (!validateForm()) {
      return;
    }

    let url = `v1/client/new`;
    /*Record do backend
        @NotEmpty String name, @NotEmpty String cpfCnpj, String number, String fantasyName, String email, String phone, String mobilePhone, String address, String addressNumber, String complement, String province, String city, String state, String postalCode, String region, String municipalInscription, String stateInscription, String observations, String externalReference, String additionalEmails*/
    const dados = {
      name: client.peopleName,
      cpfCnpj: client.peopleCpfCnpj,
      number: client.clientNumber,
      fantasyName: client.peopleFantasyName,
      email: client.peopleEmail,
      whatsapp: client.whatsapp,
      fixedPhone: client.fixedPhone,
      address: client.addressStreet,
      addressNumber: client.addressNumber,
      complement: client.addressComplement,
      province: client.addressNeighborhood,
      city: client.addressCity,
      state: client.addressState,
      postalCode: client.addressPostalCode,
      region: client.addressRegion,
      municipalInscription: client.municipalInscription,
      stateInscription: client.stateInscription,
      observations: client.observations,
      instagram: client.peopleInstagram,
      additionalEmails: client.peopleAdditionalEmails,
    };
    //console.log(dados)
    try {
      const response = await sendPost<ICreClient>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/clients");
      console.log(dados);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setClient((prevClient) => ({ ...prevClient, [name]: valor }));
    if (name === "addressPostalCode" && valor.length === 9) {
      fetchCep(valor);
    }
  };
  /*
    logradouro: string
    complemento: string
    bairro: string
    localidade: string
    uf: string
    ibge: string
    gia: string
    ddd: string
    siafi: string
    */
  async function fetchCep(cep: string) {
    let url = `https://viacep.com.br/ws/${cep}/json/`;
    const dados = {};
    //console.log(dados)
    try {
      const response = await sendSimpleGet<ICep>(url, dados, addMessages, setaLoading, navigate);
      console.log(response);
      setClient((prevClient) => ({
        ...prevClient,
        addressStreet: response.logradouro,
        addressNeighborhood: response.bairro,
        addressCity: response.localidade,
        addressState: response.uf,
      }));
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Incluir Cliente</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="radio-buttons-group-people-type-label">Tipo Pessoa</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-people-type-label"
                    defaultValue={peopleType}
                    name="peopleType"
                    onChange={(event) => {
                      setPeopleType(event.target.value);
                      if (event.target.value === "F") {
                        setClient((prevClient) => ({
                          ...prevClient,
                          peopleFantasyName: "",
                          municipalInscription: "",
                          stateInscription: "",
                        }));
                      }
                    }}
                  >
                    <FormControlLabel value="F" control={<Radio />} label="Física" />
                    <FormControlLabel value="J" control={<Radio />} label="Jurídica" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={peopleType === "F" ? "Nome" : "Razão Social"}
                  value={client.peopleName}
                  name="peopleName"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>

              {peopleType === "J" ? (
                <Grid item xs={12}>
                  <TextField
                    label="Nome Fantasia"
                    value={client.peopleFantasyName}
                    name="peopleFantasyName"
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <TextField
                  label="CPF/CNPJ"
                  value={client.peopleCpfCnpj}
                  name="peopleCpfCnpj"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={
                    peopleType === "F"
                      ? { inputComponent: CpfMaskCustom as any }
                      : { inputComponent: CnpjMaskCustom as any }
                  }
                />
              </Grid>
              {peopleType === "J" ? (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label="Inscrição Municipal"
                      value={client.municipalInscription}
                      name="municipalInscription"
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Inscrição Estadual"
                      value={client.stateInscription}
                      name="stateInscription"
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  value={client.peopleEmail}
                  name="peopleEmail"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="CEP"
                  value={client.addressPostalCode}
                  name="addressPostalCode"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: CepMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Rua"
                  value={client.addressStreet}
                  name="addressStreet"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Numero"
                  value={client.addressNumber}
                  name="addressNumber"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Complemento"
                  value={client.addressComplement}
                  name="addressComplement"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Bairro"
                  value={client.addressNeighborhood}
                  name="addressNeighborhood"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Cidade"
                  value={client.addressCity}
                  name="addressCity"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Estado"
                  value={client.addressState}
                  name="addressState"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Região"
                  value={client.addressRegion}
                  name="addressRegion"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="WhastApp"
                  value={client.whatsapp}
                  name="whatsapp"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: WhatsAppMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Celular"
                  value={client.fixedPhone}
                  name="fixedPhone"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: PhoneMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Instagram"
                  value={client.peopleInstagram}
                  name="peopleInstagram"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Numero do Cliente"
                  value={client.clientNumber}
                  name="clientNumber"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Emails Adicionais"
                  value={client.peopleAdditionalEmails}
                  name="peopleAdditionalEmails"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observações"
                  value={client.observations}
                  name="observations"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default NewClient;
