import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  SvgIcon,
  TextField,
} from "@mui/material";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import CloudArrowUpIcon from "@heroicons/react/24/solid/CloudArrowUpIcon";

import { useNavigate, useParams } from "react-router-dom";
import Titulo from "../../../../../components/elements/Titulo";
import { GlobalContext } from "../../../../context/GlobalContext";
import { ICapSuplier } from "../../../../types/Cap/Suplier/ICapSuplier";
import { ICapSuplierEditData } from "../../../../types/Cap/Suplier/ICapSuplierEditData";
import { IModule } from "../../../../types/Adm/Module/IModule";
import { sendGet, sendPost, sendSimpleGet } from "../../../../utils/httpUtils";
import "./EditSuplier.css";
import { IMaskInput } from "react-imask";
import { ICep } from "../../../../types/Generic/ICep";
import { number } from "prop-types";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const CepMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CepMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00000-000"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const PhoneMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function PhoneMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={[{ mask: "+55 (00) 0000-0000" }, { mask: "+55 (00) 00000-0000" }]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const MobileMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function MobileMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={[{ mask: "+55 (00) 0000-0000" }, { mask: "+55 (00) 00000-0000" }]}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const CpfMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CpfMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000.000.000-00"
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const CnpjMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function CnpjMaskCustom(props, ref): React.ReactElement {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00.000.000/000#-00"
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

const EditSuplier = () => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  const { id } = useParams();
  const suplierId = Number(id);

  const [suplier, setSuplier] = useState<ICapSuplierEditData>({
    id: suplierId,
    peopleName: "",
    peopleCpfCnpj: "",
    municipalInscription: "",
    stateInscription: "",
    addressPostalCode: "",
    addressStreet: "",
    addressNumber: "",
    addressComplement: "",
    addressNeighborhood: "",
    addressCity: "",
    addressState: "",
    addressRegion: "",
    whatsapp: "",
    fixedPhone: "",
    peopleInstagram: "",
    suplierNumber: "",
    peopleAdditionalEmails: "",
    observations: "",
  } as ICapSuplierEditData);

  const [peopleType, setPeopleType] = useState("");

  let navigate = useNavigate();
  function handleClickVoltar() {
    navigate("/area-logada/cap/supliers");
  }

  function validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function validateCpf(cpf: string) {
    if (cpf.length !== 11) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  }

  function validateCnpj(cnpj: string) {
    if (cnpj === "" || cnpj.length !== 14) {
      return false;
    }

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999"
    ) {
      return false;
    }

    // Valida DVs
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    const digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += Number(numbers.charAt(size - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(0))) {
      return false;
    }

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += Number(numbers.charAt(size - i)) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(1))) {
      return false;
    }

    return true;
  }

  function validateForm() {
    //Aplicar trim em todos os campos
    //colocar verificação de null ou undefined antes do trim
    if (suplier.peopleName !== null && suplier.peopleName !== undefined) {
      suplier.peopleName = suplier.peopleName.trim();
    }
    if (suplier.peopleCpfCnpj !== null && suplier.peopleCpfCnpj !== undefined) {
      suplier.peopleCpfCnpj = suplier.peopleCpfCnpj.trim().replace(/\D/g, "");
    }
    if (suplier.peopleEmail !== null && suplier.peopleEmail !== undefined) {
      suplier.peopleEmail = suplier.peopleEmail.trim();
    }
    if (suplier.addressPostalCode !== null && suplier.addressPostalCode !== undefined) {
      suplier.addressPostalCode = suplier.addressPostalCode.trim().replace(/\D/g, "");
    }
    if (suplier.addressStreet !== null && suplier.addressStreet !== undefined) {
      suplier.addressStreet = suplier.addressStreet.trim();
    }
    if (suplier.addressComplement !== null && suplier.addressComplement !== undefined) {
      suplier.addressComplement = suplier.addressComplement.trim();
    }
    if (suplier.addressNeighborhood !== null && suplier.addressNeighborhood !== undefined) {
      suplier.addressNeighborhood = suplier.addressNeighborhood.trim();
    }
    if (suplier.addressCity !== null && suplier.addressCity !== undefined) {
      suplier.addressCity = suplier.addressCity.trim();
    }
    if (suplier.addressState !== null && suplier.addressState !== undefined) {
      suplier.addressState = suplier.addressState.trim();
    }
    if (suplier.addressRegion !== null && suplier.addressRegion !== undefined) {
      suplier.addressRegion = suplier.addressRegion.trim();
    }
    if (suplier.whatsapp !== null && suplier.whatsapp !== undefined) {
      suplier.whatsapp = suplier.whatsapp.trim().replace(/\D/g, "");
    }
    if (suplier.fixedPhone !== null && suplier.fixedPhone !== undefined) {
      suplier.fixedPhone = suplier.fixedPhone;
      suplier.fixedPhone = suplier.fixedPhone.trim().replace(/\D/g, "");
    }
    if (suplier.peopleInstagram !== null && suplier.peopleInstagram !== undefined) {
      suplier.peopleInstagram = suplier.peopleInstagram.trim();
    }
    if (suplier.suplierNumber !== null && suplier.suplierNumber !== undefined) {
      suplier.suplierNumber = suplier.suplierNumber.trim().replace(/\D/g, "");
    }
    if (suplier.peopleAdditionalEmails !== null && suplier.peopleAdditionalEmails !== undefined) {
      suplier.peopleAdditionalEmails = suplier.peopleAdditionalEmails.trim();
    }
    if (suplier.observations !== null && suplier.observations !== undefined) {
      suplier.observations = suplier.observations.trim();
    }

    if (peopleType !== "F") {
      if (suplier.municipalInscription !== null && suplier.municipalInscription !== undefined) {
        suplier.municipalInscription = suplier.municipalInscription.trim();
      }
      if (suplier.stateInscription !== null && suplier.stateInscription !== undefined) {
        suplier.stateInscription = suplier.stateInscription.trim();
      }
    } else {
      suplier.municipalInscription = "";
      suplier.stateInscription = "";
    }

    if (suplier.peopleName === "") {
      addMessages(["Nome é obrigatório"]);
      return false;
    }
    if (suplier.peopleCpfCnpj === "") {
      addMessages(["CPF/CNPJ é obrigatório"]);
      return false;
    } else {
      if (peopleType === "F") {
        if (!validateCpf(suplier.peopleCpfCnpj)) {
          addMessages(["CPF inválido"]);
          return false;
        }
      } else {
        if (!validateCnpj(suplier.peopleCpfCnpj)) {
          addMessages(["CNPJ inválido"]);
          return false;
        }
      }
    }
    if (suplier.addressPostalCode === "") {
      addMessages(["CEP do endereço é obrigatório"]);
      return false;
    }
    if (suplier.addressNumber === "") {
      addMessages(["Número do endereço é obrigatório"]);
      return false;
    }

    if (suplier.peopleEmail !== "") {
      if (!validateEmail(suplier.peopleEmail)) {
        addMessages(["Email inválido"]);
        return false;
      }
    }
    if (suplier.whatsapp !== undefined && suplier.whatsapp !== "" && suplier.whatsapp !== null) {
      if (suplier.whatsapp.length > 13 || suplier.whatsapp.length < 12) {
        addMessages(["Whatsapp inválido"]);
        return false;
      }
    }
    if (
      suplier.fixedPhone !== undefined &&
      suplier.fixedPhone !== "" &&
      suplier.fixedPhone !== null
    ) {
      if (suplier.fixedPhone.length > 13 || suplier.fixedPhone.length < 12) {
        addMessages(["Telefone inválido"]);
        return false;
      }
    }
    return true;
  }

  async function handleClickSalvar() {
    if (!validateForm()) {
      return;
    }
    let url = `v1/cap/supliers/edit/${suplierId}`;
    const dados = {
      id: suplier.id,
      name: suplier.peopleName,
      cpfCnpj: suplier.peopleCpfCnpj,
      suplierNumber: suplier.suplierNumber,
      number: suplier.number,
      email: suplier.peopleEmail,
      whatsapp: suplier.whatsapp,
      fixedPhone: suplier.fixedPhone,
      address: suplier.addressStreet,
      addressNumber: suplier.addressNumber,
      complement: suplier.addressComplement,
      province: suplier.addressNeighborhood,
      city: suplier.addressCity,
      state: suplier.addressState,
      postalCode: suplier.addressPostalCode,
      region: suplier.addressRegion,
      municipalInscription: suplier.municipalInscription,
      stateInscription: suplier.stateInscription,
      observations: suplier.observations,
      instagram: suplier.peopleInstagram,
      additionalEmails: suplier.peopleAdditionalEmails,
    };
    try {
      const response = await sendPost<ICapSuplier>(url, dados, addMessages, setaLoading, navigate);
      navigate("/area-logada/cap/supliers");
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, type } = target;
    let valor = target.value;

    setSuplier((prevSuplier) => ({ ...prevSuplier, [name]: valor }));

    if (name === "addressPostalCode" && valor.length === 9) {
      fetchCep(valor);
    }
  };

  async function fetchCep(cep: string) {
    let url = `https://viacep.com.br/ws/${cep}/json/`;
    const dados = {};
    //console.log(dados)
    try {
      const response = await sendSimpleGet<ICep>(url, dados, addMessages, setaLoading, navigate);
      console.log(response);
      setSuplier((prevSuplier) => ({
        ...prevSuplier,
        addressStreet: response.logradouro,
        addressNeighborhood: response.bairro,
        addressCity: response.localidade,
        addressState: response.uf,
      }));
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function consultaSuplier() {
    let url = `v1/cap/supliers/${suplierId}`;
    const dados = {};
    try {
      const response = await sendGet<ICapSuplier>(url, dados, addMessages, setaLoading, navigate);
      setSuplier(response);
      console.log(response);
      if (response.peopleCpfCnpj.length > 11) {
        setPeopleType("J");
      } else {
        setPeopleType("F");
      }
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    consultaSuplier();
  }, []);

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Editar Fornecedor</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ChevronLeftIcon />
                      </SvgIcon>
                    }
                    onClick={handleClickVoltar}
                  >
                    Voltar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="radio-buttons-group-people-type-label">Tipo Pessoa</FormLabel>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-people-type-label"
                    name="peopleType"
                  >
                    <FormControlLabel
                      value="F"
                      control={<Radio />}
                      checked={peopleType === "F"}
                      label="Física"
                      disabled
                    />
                    <FormControlLabel
                      value="J"
                      control={<Radio />}
                      checked={peopleType === "J"}
                      label="Jurídica"
                      disabled
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={peopleType === "F" ? "Nome" : "Razão Social"}
                  value={suplier.peopleName}
                  name="peopleName"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="CPF/CNPJ"
                  value={suplier.peopleCpfCnpj}
                  name="peopleCpfCnpj"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={
                    peopleType === "F"
                      ? { inputComponent: CpfMaskCustom as any }
                      : { inputComponent: CnpjMaskCustom as any }
                  }
                  disabled
                />
              </Grid>
              {peopleType === "J" ? (
                <>
                  <Grid item xs={12}>
                    <TextField
                      label="Inscrição Municipal"
                      value={suplier.municipalInscription}
                      name="municipalInscription"
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Inscrição Estadual"
                      value={suplier.stateInscription}
                      name="stateInscription"
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  value={suplier.peopleEmail}
                  name="peopleEmail"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="CEP"
                  value={suplier.addressPostalCode}
                  name="addressPostalCode"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: CepMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Rua"
                  value={suplier.addressStreet}
                  name="addressStreet"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Numero"
                  value={suplier.addressNumber}
                  name="addressNumber"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Complemento"
                  value={suplier.addressComplement}
                  name="addressComplement"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Bairro"
                  value={suplier.addressNeighborhood}
                  name="addressNeighborhood"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Cidade"
                  value={suplier.addressCity}
                  name="addressCity"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Estado"
                  value={suplier.addressState}
                  name="addressState"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Região"
                  value={suplier.addressRegion}
                  name="addressRegion"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Whatsapp"
                  value={suplier.whatsapp}
                  name="whatsapp"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: PhoneMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Celular"
                  value={suplier.fixedPhone}
                  name="fixedPhone"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  InputProps={{ inputComponent: MobileMaskCustom as any }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Numero do Fornecedor"
                  value={suplier.suplierNumber}
                  name="suplierNumber"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Emails Adicionais"
                  value={suplier.peopleAdditionalEmails}
                  name="peopleAdditionalEmails"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Observações"
                  value={suplier.observations}
                  name="observations"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={handleClickSalvar}
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={
                    <SvgIcon fontSize="small">
                      <CloudArrowUpIcon />
                    </SvgIcon>
                  }
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default EditSuplier;
