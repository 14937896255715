import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import { IconButton, SvgIcon, Tooltip } from "@mui/material";

interface DeleteActionButtonProps {
  children?: React.ReactNode;
  handleClickExcluir: (row: any) => void;
  row: any;
  disabled?: number;
}

const iconStyles = {
  fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
  marginRight: "8px", // Ajuste o espaçamento entre os ícones
};

export default function DeleteActionButton(props: DeleteActionButtonProps) {
  return (
    <IconButton
      onClick={() => props.handleClickExcluir(props.row)}
      sx={{ padding: "0px 4px 0px 0px" }}
      disabled={props.row.qtdChats > 0}
    >
      <Tooltip title="Excluir">
        <SvgIcon fontSize="small">
          <TrashIcon aria-label="Excluir" style={iconStyles} />
        </SvgIcon>
      </Tooltip>
    </IconButton>
  );
}
