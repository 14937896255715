import DocumentMagnifyingGlassIcon from "@heroicons/react/24/solid/DocumentMagnifyingGlassIcon";
import { IconButton, SvgIcon, Tooltip } from "@mui/material";

interface DetailsActionButtonProps {
  children?: React.ReactNode;
  handleClickDetails: (row: any) => void;
  row: any;
  disabled?: boolean;
}

const iconStyles = {
  fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
  marginRight: "8px", // Ajuste o espaçamento entre os ícones
};

export default function DetailsActionButton(props: DetailsActionButtonProps) {
  return (
    <Tooltip title="Detalhes">
      <IconButton
        onClick={() => props.handleClickDetails(props.row.id)}
        style={{ padding: "0px 4px 0px 0px" }}
        disabled={props.disabled}
      >
        <SvgIcon fontSize="small">
          <DocumentMagnifyingGlassIcon aria-label="Detalhes" style={iconStyles} />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
}
