import PencilSquareIcon from "@heroicons/react/24/solid/PencilSquareIcon";
import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import { IWallet } from "../../../core/types/Cre/Wallet/IWallet";
import { useNavigate } from "react-router-dom";

interface EditActionButtonProps {
  children?: React.ReactNode;
  handleClickEditar: (row: any) => void;
  row: any;
  disabled?: boolean;
}

const iconStyles = {
  fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
  marginRight: "8px", // Ajuste o espaçamento entre os ícones
};

export default function EditAcctionButton(props: EditActionButtonProps) {
  return (
    <IconButton
      onClick={() => props.handleClickEditar(props.row)}
      sx={{ padding: "0px 4px 0px 0px" }}
      disabled={props.disabled ? props.disabled : false}
    >
      <Tooltip title="Editar">
        <SvgIcon fontSize="small">
          <PencilSquareIcon aria-label="Editar" style={iconStyles} />
        </SvgIcon>
      </Tooltip>
    </IconButton>
  );
}
