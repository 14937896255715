import React, { useContext, useEffect, useState } from "react";

import "./Contas.css";

import PencilSquareIcon from "@heroicons/react/24/solid/PencilSquareIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";

import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";

import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import XCircleIcon from "@heroicons/react/24/solid/XCircleIcon";
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomTableCell from "../../../../components/elements/CustomTableCell";
import Paginacao from "../../../../components/elements/Paginacao";
import Titulo from "../../../../components/elements/Titulo";
import { GlobalContext } from "../../../context/GlobalContext";
import { IAccount } from "../../../types/Adm/Account/IAccount";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { ISuccessoMensagensResponse } from "../../../types/Generic/ISuccessoMensagensResponse";
import { formataTimeStampLocal } from "../../../utils/date-utils";
import { sendDelete, sendPost } from "../../../utils/httpUtils";
import EditAcctionButton from "../../../../components/elements/EditActionButton";
import DeleteActionButton from "../../../../components/elements/DeleteActionButton";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import TableHeader from "../../../../components/elements/TableHeader";
import CustomACtionBox from "../../../../components/elements/Crud/CustomTableBody";
import CheckedActionButton from "../../../../components/elements/CheckedActionButto";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import contasService from "../../../services/admin/ContasService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Contas = () => {
  let navigate = useNavigate();

  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [contas, setContas] = useState<IAccount[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>("NAME,asc");

  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [itensPerPage, setItensPerPage] = useState<number>(5);

  const handleItemsPerPageChange = (itens: number) => {
    searchAccount(0, itens, sort);
  };

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  async function searchAccount(
    page: number,
    itensPerPage: number,
    sort?: string,
    searchQuery?: string,
  ) {
    setItensPerPage(itensPerPage);
    const response = await contasService.searchFilteredAccounts(
      addMessages,
      setaLoading,
      navigate,
      page,
      itensPerPage,
      sort,
      searchQuery,
    );
    if (response) {
      setTotalPages(response.totalPages);
      setContas(response.content);
      setCurrentPage(page + 1);
      setTotalRecords(response.totalElements);
    } else {
      setTotalPages(0);
      setContas([]);
      addMessages(["Não houve resposta do serviço de contas"]);
    }
  }

  async function excluiConta(id: number) {
    let url = `/v1/admin/accounts/${id}`;
    const dados = {};
    try {
      const response = await sendDelete<ISuccessoMensagensResponse>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      addMessages(response.messages);
      searchAccount(0, itensPerPage, sort);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    searchAccount(0, itensPerPage, sort);
  }, []);

  function preventDefault(event: React.MouseEvent) {
    event.preventDefault();
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    searchAccount(page - 1, itensPerPage, sort);
  };

  function handleClickEditar(row: IAccount): void {
    navigate(`/area-logada/admin/edita-conta/${row.id}`);
  }

  function handleClickNovo(): void {
    navigate(`/area-logada/admin/nova-conta`);
  }

  function handleClickExcluir(
    row: IAccount,
  ): React.MouseEventHandler<HTMLButtonElement> | undefined {
    if (confirm(`Tem certeza que deseja excluir a conta ${row.name}`)) {
      excluiConta(row.id);
    }
    return;
  }

  async function handleClickChangeActive(row: IAccount): Promise<void> {
    let url = `v1/admin/accounts/update-active/${row.id}`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<ISuccessoMensagensResponse>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      searchAccount(currentPage - 1, itensPerPage, sort);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  async function handleClickChangeBloqued(row: IAccount): Promise<void> {
    let url = `v1/admin/accounts/update-bloqued/${row.id}`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<ISuccessoMensagensResponse>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      searchAccount(currentPage - 1, itensPerPage, sort);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    searchAccount(currentPage - 1, itensPerPage, sortNovo);
    console.log(sortNovo);
  }

  const columns = [
    { displayName: "ID", columnName: "sa.ID" },
    { displayName: "NOME", columnName: "sa.NAME" },
    { displayName: "NOME FANTASIA", columnName: "sa.FANTASY_NAME" },
    { displayName: "CNPJ", columnName: "sa.CNPJ" },
    { displayName: "ATIVO", columnName: "sa.ACTIVE" },
    { displayName: "BLOQUEADO", columnName: "sa.BLOQUED" },
    { displayName: "Ações", columnName: null },
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Contas</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Importar
                  </Button>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Exportar
                  </Button>
                </Stack>
              </Stack>
              <Stack>
                <Button
                  onClick={() => handleClickNovo()}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {contas.map((row, index) => (
                    <CustomStripedTableRow row={row.id} index={index}>
                      <CustomTableCell>{row.id}</CustomTableCell>
                      <CustomTableCell>{row.name}</CustomTableCell>
                      <CustomTableCell>{row.fantasyName}</CustomTableCell>
                      <CustomTableCell>{row.cnpj}</CustomTableCell>
                      <CustomTableCell>
                        <CheckedActionButton
                          row={row}
                          isChecked={row.active}
                          handleClickChangeChecked={handleClickChangeActive}
                        />
                      </CustomTableCell>
                      <CustomTableCell>
                        <CheckedActionButton
                          row={row}
                          isChecked={row.bloqued}
                          handleClickChangeChecked={handleClickChangeBloqued}
                        />
                      </CustomTableCell>
                      <CustomTableCell>
                        <CustomACtionBox>
                          <EditAcctionButton row={row} handleClickEditar={handleClickEditar} />
                          <DeleteActionButton row={row} handleClickExcluir={handleClickExcluir} />
                        </CustomACtionBox>
                      </CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
              </CustomTable>
            </CustomTableContainer>
            <Paginacao
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              totalRecords={totalRecords}
              itensPerPage={itensPerPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Contas;
