import { NavigateFunction } from "react-router-dom";
import { sendPost } from "../../../utils/httpUtils";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { ICapSuplier } from "../../../types/Cap/Suplier/ICapSuplier";

const suplierService = {
    searchFilteredSuplier: async function (
        addMessages: (messages: string[]) => void,
        setaLoading: (loading: boolean) => void,
        navigate: NavigateFunction,
        page: number,
        itensPerPage: number,
        sort?: string,
        suplierName?: string,
      ) {
        let url = `v1/cap/supliers/list?size=${itensPerPage}&page=${page}${sort ? `&sort=${sort}` : ""}`;
        const dados = {
            suplierName: suplierName,
        };
        console.log(dados);
        try {
          const response = await sendPost<IPaginatedList<ICapSuplier>>(
            url,
            dados,
            addMessages,
            setaLoading,
            navigate,
          );
          console.log("response:", response);
          return response;
        } catch (error) {
          // Handle the error here if necessary
          console.log(error);
        }
      },
}

export default suplierService;