import { Route, Routes } from "react-router-dom";
import Principal from "../components/layouts/Principal";
import Contas from "../core/pages/Adm/Contas";
import EdicaoContas from "../core/pages/Adm/Contas/EdicaoContas";
import InclusaoContas from "../core/pages/Adm/Contas/InclusaoContas";
import Menus from "../core/pages/Adm/Menus";
import EdicaoMenus from "../core/pages/Adm/Menus/EdicaoMenus";
import NewMenu from "../core/pages/Adm/Menus/InclusaoMenus";
import Modules from "../core/pages/Adm/Modules";
import EditModule from "../core/pages/Adm/Modules/EditModule";
import NewModule from "../core/pages/Adm/Modules/NewModule";
import Papeis from "../core/pages/Adm/Papeis";
import EdicaoPapeis from "../core/pages/Adm/Papeis/EdicaoPapeis";
import InclusaoPapeis from "../core/pages/Adm/Papeis/InclusaoPapeis";
import Plans from "../core/pages/Adm/Plans";
import EdicaoPlanos from "../core/pages/Adm/Plans/EdicaoPlanos";
import InclusaoPlanos from "../core/pages/Adm/Plans/InclusaoPlanos";
import Usuarios from "../core/pages/Adm/Usuarios";
import EdicaoUsuarios from "../core/pages/Adm/Usuarios/EdicaoUsuarios";
import InclusaoUsuarios from "../core/pages/Adm/Usuarios/InclusaoUsuarios";
import Wallet from "../core/pages/Cre/Wallet";
import EdicaoWallet from "../core/pages/Cre/Wallet/EdicaoWallet";
import InclusaoWallet from "../core/pages/Cre/Wallet/InclusaoWallet";
import Bill from "../core/pages/Cre/Bill";
import EditBill from "../core/pages/Cre/Bill/EditBill";
import NewBill from "../core/pages/Cre/Bill/NewBill";
import Category from "../core/pages/Cre/Category";
import CapCategory from "../core/pages/Cap/Category";
import CapEditCategory from "../core/pages/Cap/Category/EditCategory";
import CapNewCategory from "../core/pages/Cap/Category/NewCategory";
import EditCategory from "../core/pages/Cre/Category/EditCategory";
import NewCategory from "../core/pages/Cre/Category/NewCategory";
import Client from "../core/pages/Cre/Clients";
import EditClient from "../core/pages/Cre/Clients/EditClient";
import NewClient from "../core/pages/Cre/Clients/NewClient";
import Fechamentos from "../core/pages/Cre/Fechamentos";
import DetalhesFechamento from "../core/pages/Cre/Fechamentos/DetalhesFechamento";
import Invoice from "../core/pages/Cre/Invoice";
import EditInvoice from "../core/pages/Cre/Invoice/EditInvoice";
import InclusaoInvoice from "../core/pages/Cre/Invoice/InclusaoInvoice";
import Home from "../core/pages/Login";
import PaginaBase from "../core/pages/PaginaBase";
import Config from "../core/pages/Sys/Config";
import Chats from "../core/pages/Wpp/Chats";
import Contacts from "../core/pages/Wpp/Contacts";
import Conta from "../core/pages/main/Conta";
import Dashboard from "../core/pages/main/Dashboard";
import CapPayments from "../core/pages/Cap/Payments";
import CapNewPayment from "../core/pages/Cap/Payments/NewPayment";
import CapBill from "../core/pages/Cap/Bill";
import NewCapBill from "../core/pages/Cap/Bill/NewBill";
import EditCapBill from "../core/pages/Cap/Bill/EditBill";
import Supliers from "../core/pages/Cap/Supliers";
import NewSuplier from "../core/pages/Cap/Supliers/NewSuplier";
import EditSuplier from "../core/pages/Cap/Supliers/EditSuplier";
import BankAccount from "../core/pages/Ceb/BankAccount";
import NewBankAccount from "../core/pages/Ceb/BankAccount/NewBankAccount";
import EditBankAccount from "../core/pages/Ceb/BankAccount/EditBankAccount";
import CapRecurrency from "../core/pages/Cap/Recurrency";
import NewCapRecurrency from "../core/pages/Cap/Recurrency/NewRecurrency";

const Rotas = () => {
  return (
    <Routes>
      <Route path="/" element={<PaginaBase />}>
        <Route path="/" element={<Home />} />
        <Route path="/area-logada" element={<Principal />}>
          <Route path="/area-logada/conta" element={<Conta />} />
          <Route path="/area-logada/admin/menus" element={<Menus />} />
          <Route path="/area-logada/admin/novo-menu" element={<NewMenu />} />
          <Route path="/area-logada/admin/edita-menu/:id" element={<EdicaoMenus />} />
          <Route path="/area-logada/admin/modules" element={<Modules />} />
          <Route path="/area-logada/admin/new-module" element={<NewModule />} />
          <Route path="/area-logada/admin/edit-module/:id" element={<EditModule />} />
          <Route path="/area-logada/admin/usuarios" element={<Usuarios />} />
          <Route path="/area-logada/admin/novo-usuario" element={<InclusaoUsuarios />} />
          <Route path="/area-logada/admin/edita-usuario/:id" element={<EdicaoUsuarios />} />
          <Route path="/area-logada/admin/contas" element={<Contas />} />
          <Route path="/area-logada/admin/nova-conta" element={<InclusaoContas />} />
          <Route path="/area-logada/admin/edita-conta/:id" element={<EdicaoContas />} />
          <Route path="/area-logada/admin/papeis" element={<Papeis />} />
          <Route path="/area-logada/admin/novo-papel" element={<InclusaoPapeis />} />
          <Route path="/area-logada/admin/edita-papel/:id" element={<EdicaoPapeis />} />
          <Route path="/area-logada/admin/planos" element={<Plans />} />
          <Route path="/area-logada/admin/novo-plano" element={<InclusaoPlanos />} />
          <Route path="/area-logada/admin/edita-plano/:id" element={<EdicaoPlanos />} />
          <Route path="/area-logada/dashboard" element={<Dashboard />} />
          <Route path="/area-logada/fechamentos" element={<Fechamentos />} />
          <Route path="/area-logada/detalhes-fechamento/:id" element={<DetalhesFechamento />} />
          <Route path="/area-logada/chats" element={<Chats />} />
          <Route path="/area-logada/contacts" element={<Contacts />} />
          <Route path="/area-logada/config" element={<Config />} />
          <Route path="/area-logada/wallet" element={<Wallet />} />
          <Route path="/area-logada/new-wallet" element={<InclusaoWallet />} />
          <Route path="/area-logada/edit-wallet/:id" element={<EdicaoWallet />} />
          <Route path="/area-logada/cre/invoice" element={<Invoice />} />
          <Route path="/area-logada/cre/new-invoice" element={<InclusaoInvoice />} />
          <Route path="/area-logada/cre/edit-invoice/:id" element={<EditInvoice />} />
          <Route path="/area-logada/clients" element={<Client />} />
          <Route path="/area-logada/new-client" element={<NewClient />} />
          <Route path="/area-logada/edit-client/:id" element={<EditClient />} />
          <Route path="/area-logada/cre/bill" element={<Bill />} />
          <Route path="/area-logada/cre/new-bill" element={<NewBill />} />
          <Route path="/area-logada/cre/edit-bill/:id" element={<EditBill />} />
          <Route path="/area-logada/cre/category" element={<Category />} />
          <Route path="/area-logada/cre/new-category" element={<NewCategory />} />
          <Route path="/area-logada/cre/edit-category/:id" element={<EditCategory />} />
          <Route path="/area-logada/cap/category" element={<CapCategory />} />
          <Route path="/area-logada/cap/new-category" element={<CapNewCategory />} />
          <Route path="/area-logada/cap/edit-category/:id" element={<CapEditCategory />} />
          <Route path="/area-logada/cap/payment" element={<CapPayments />} />
          <Route path="/area-logada/cap/new-payment" element={<CapNewPayment />} />
          <Route path="/area-logada/cap/bill" element={<CapBill />} />
          <Route path="/area-logada/cap/new-bill" element={<NewCapBill />} />
          <Route path="/area-logada/cap/edit-bill/:id" element={<EditCapBill />} />
          <Route path="/area-logada/cap/supliers" element={<Supliers />} />
          <Route path="/area-logada/cap/new-suplier" element={<NewSuplier />} />
          <Route path="/area-logada/cap/edit-suplier/:id" element={<EditSuplier />} />
          <Route path="/area-logada/cap/recurrency" element={<CapRecurrency />} />
          <Route path="/area-logada/cap/new-recurrency" element={<NewCapRecurrency />} />
          <Route path="/area-logada/cap/edit-recurrency/:id" element={<EditCapBill />} />
          <Route path="/area-logada/ceb/bank-account" element={<BankAccount />} />
          <Route path="/area-logada/ceb/new-bank-account" element={<NewBankAccount />} />
          <Route path="/area-logada/ceb/edit-bank-account/:id" element={<EditBankAccount />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Rotas;
