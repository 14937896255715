import React, { useContext, useEffect, useState } from "react";

import "./Chats.css";

import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";

import PlusIcon from "@heroicons/react/24/solid/PlusIcon";

import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  styled,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import CustomTableCell from "../../../../components/elements/CustomTableCell";
import Paginacao from "../../../../components/elements/Paginacao";
import Titulo from "../../../../components/elements/Titulo";
import { GlobalContext } from "../../../context/GlobalContext";
import { IChat } from "../../../types/Wpp/Chat/IChat";
import { IClosure } from "../../../types/Cre/Closure/IClosure";
import { IPaginatedList } from "../../../types/Generic/IPaginatedList";
import { formataTimeStampLocal } from "../../../utils/date-utils";
import { sendPost } from "../../../utils/httpUtils";
import CustomTableContainer from "../../../../components/elements/Crud/CustomTableContainer";
import CustomTable from "../../../../components/elements/Crud/CustomTable";
import TableHeader from "../../../../components/elements/TableHeader";
import CustomTableBody from "../../../../components/elements/Crud/CustomTableBody";
import CustomStripedTableRow from "../../../../components/elements/Crud/CustomStripedTableRow";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Chats = () => {
  let navigate = useNavigate();

  const { addMessages, setaLoading } = useContext(GlobalContext);

  const [openImport, setOpenImport] = React.useState(false);
  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const [chats, setChats] = useState<IChat[]>([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState<number>(0);

  const [sort, setSort] = useState<string>("sp.NAME,asc");

  const iconStyles = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
  };
  const iconStylesDisabled = {
    fontSize: "24px", // Ajuste o tamanho dos ícones conforme necessário
    marginRight: "8px", // Ajuste o espaçamento entre os ícones
    opacity: 0.1,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  async function consultaChats(page: number, sort?: string) {
    let url = `v1/wpp/chats/list?size=10&page=${page}${sort ? `&sort=${sort}` : ""}`;
    const dados = {};
    try {
      const response = await sendPost<IPaginatedList<IChat>>(
        url,
        dados,
        addMessages,
        setaLoading,
        navigate,
      );
      setTotalPages(response.totalPages);
      setChats(response.content);
      setCurrentPage(page + 1);
    } catch (error) {
      // Handle the error here if necessary
      console.log(error);
    }
  }

  useEffect(() => {
    consultaChats(0);
  }, []);

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    consultaChats(page - 1);
  };

  function handleEdit(row: IClosure): void {
    throw new Error("Function not implemented.");
  }

  function handleDelete(row: IClosure): void {
    throw new Error("Function not implemented.");
  }

  function handleSortChange(sortNovo: string) {
    setSort(sortNovo);
    consultaChats(currentPage - 1, sortNovo);
  }

  const columns = [
    { displayName: "Nome Contato", columnName: "sp.NAME" },
    { displayName: "Telefone Contato", columnName: "wc2.WHATSAPP" },
    { displayName: "Mensagem", columnName: "wc.TEXT_MESSAGE" },
    { displayName: "Arquivo", columnName: "wc.MESSAGE_FILE_NAME" },
    { displayName: "Status", columnName: "wc.MESSAGE_STATUS" },
  ];

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Titulo>Chats</Titulo>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    }
                    disabled
                  >
                    Importar
                  </Button>
                  <Button
                    disabled
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Exportar
                  </Button>
                </Stack>
              </Stack>
              <Stack>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  disabled
                >
                  Novo
                </Button>
              </Stack>
            </Stack>
            <CustomTableContainer>
              <CustomTable>
                <TableHeader
                  initialSortedDirection={sort.split(",")[1] === "desc" ? "desc" : "asc"}
                  initialSortedField={sort.split(",")[0]}
                  columns={columns} // Substitua 'columns' pelo array de colunas que você tem
                  handleSortSearch={handleSortChange} // Substitua 'handleSortChange' pela função que você tem para lidar com a mudança de ordenação
                />
                <CustomTableBody>
                  {chats.map((row, index) => (
                    <CustomStripedTableRow row={row} index={index}>
                      <CustomTableCell>{row.contactName}</CustomTableCell>
                      <CustomTableCell>{row.contactPhone}</CustomTableCell>
                      <CustomTableCell>
                        <Tooltip title={row.textMessage}>
                          <span>
                            {row.textMessage && row.textMessage?.substring(0, 20) + " ... "}
                          </span>
                        </Tooltip>
                      </CustomTableCell>
                      <CustomTableCell>{row.messageFileName}</CustomTableCell>
                      <CustomTableCell>{row.messageStatus}</CustomTableCell>
                    </CustomStripedTableRow>
                  ))}
                </CustomTableBody>
                <Paginacao
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </CustomTable>
            </CustomTableContainer>
          </Stack>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default Chats;
