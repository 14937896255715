// httpUtils.ts
import { NavigateFunction, useNavigate } from "react-router-dom";
import http from "../services/http";
import { IResponse } from "../types/Generic/IResponse";

export async function sendRequest<T>(
  tipoRequest: string,
  url: string,
  data: any,
  addMessages: (messages: string[]) => void,
  setaLoading: (loading: boolean) => void,
  navigate: NavigateFunction,
  headers?: { headers: { "Content-Type": string } } | undefined,
): Promise<T> {
  //setaLoading(true);
  try {
    let response: any;
    switch (tipoRequest) {
      case "POST":
        response = headers
          ? await http.post<IResponse<T>>(url, data, headers)
          : await http.post<IResponse<T>>(url, data);
        break;
      case "GET":
        response = await http.get<IResponse<T>>(url, data);
        break;
      case "SIMPLE-GET":
        response = await http.get<T>(url, data);
        return response.data as T;
      case "DELETE":
        response = await http.delete<IResponse<T>>(url, data);
        break;
      case "PUT":
        response = headers
          ? await http.put<IResponse<T>>(url, data, headers)
          : await http.put<IResponse<T>>(url, data);
        break;
      default:
        break;
    }

    if (response.status !== 200) {
      console.log("erro" + response.status);
    }
    return response.data.data as T; // Adjust the return type to IResponse<T>
  } catch (error: any) {
    if (error.response) {
      console.log(`Erro ${error.response.status}:`);
      let messages: string[] = [];
      if (error.response.data.message) {
        messages.push(error.response.data.message);
      }

      switch (error.response.status) {
        case 400:
          messages.push("Dados inválidos");
          messages.push(error.response.data.errors.join(", "));
          addMessages(messages);

          break;
        case 401:
          messages.push("Você não está autorizado à acessar este recurso");
          addMessages(messages);
          sessionStorage.removeItem("token");
          navigate("/");
          break;
        case 403:
          messages.push("Você não tem permissão para acessar este recurso");
          addMessages(messages);
          sessionStorage.removeItem("token");
          navigate("/");
          break;
        case 404:
          messages.push("Recurso não encontrado");
          break;
        case 500:
          messages.push("Erro interno do servidor");
          addMessages(messages);
          break;
        default:
          messages.push("Erro desconhecido: " + error.response.status);
          break;
      }
    } else {
      addMessages([error.message]);
    }
    throw error; // Re-throw the error so it can be caught and handled by the calling code
  } finally {
    //setaLoading(false);
  }
}

export async function sendPost<T>(
  url: string,
  data: any,
  addMessages: (messages: string[]) => void,
  setaLoading: (loading: boolean) => void,
  navigate: NavigateFunction,
  headers?: { headers: { "Content-Type": string } } | undefined,
): Promise<T> {
  return sendRequest<T>("POST", url, data, addMessages, setaLoading, navigate, headers);
}

export async function sendGet<T>(
  url: string,
  data: any,
  addMessages: (messages: string[]) => void,
  setaLoading: (loading: boolean) => void,
  navigate: NavigateFunction,
  headers?: { headers: { "Content-Type": string } } | undefined,
): Promise<T> {
  return sendRequest<T>("GET", url, data, addMessages, setaLoading, navigate, headers);
}

export async function sendSimpleGet<T>(
  url: string,
  data: any,
  addMessages: (messages: string[]) => void,
  setaLoading: (loading: boolean) => void,
  navigate: NavigateFunction,
  headers?: { headers: { "Content-Type": string } } | undefined,
): Promise<T> {
  return sendRequest<T>("SIMPLE-GET", url, data, addMessages, setaLoading, navigate, headers);
}

export async function sendDelete<T>(
  url: string,
  data: any,
  addMessages: (messages: string[]) => void,
  setaLoading: (loading: boolean) => void,
  navigate: NavigateFunction,
  headers?: { headers: { "Content-Type": string } } | undefined,
): Promise<T> {
  return sendRequest<T>("DELETE", url, data, addMessages, setaLoading, navigate, headers);
}

export async function sendPut<T>(
  url: string,
  data: any,
  addMessages: (messages: string[]) => void,
  setaLoading: (loading: boolean) => void,
  navigate: NavigateFunction,
  headers?: { headers: { "Content-Type": string } } | undefined,
): Promise<T> {
  return sendRequest<T>("PUT", url, data, addMessages, setaLoading, navigate, headers);
}
