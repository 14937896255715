import { Checkbox, FormControlLabel, Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { IRole } from "../../../../core/types/Adm/Role/IRole";
import rolesService from "../../../../core/services/admin/RolesService";
import { GlobalContext } from "../../../../core/context/GlobalContext";
import { useNavigate } from "react-router-dom";

interface Props {
  papeisSelecionados: IRole[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, role: IRole) => void;
}

const RolesCheckboxGrid: React.FC<Props> = ({ papeisSelecionados, handleChange }) => {
  const { addMessages, setaLoading } = useContext(GlobalContext);

  let navigate = useNavigate();

  const [papeis, setPapeis] = useState<IRole[]>([]);

  useEffect(() => {
    rolesService
      .consultaTodasRoles(addMessages, setaLoading, navigate)
      .then((papeis) => setPapeis(papeis));
  }, []);

  return (
    <Grid item xs={12} style={{ padding: "20px" }}>
      <Paper elevation={5} style={{ padding: "20px" }}>
        <Typography variant="h6">Papéis</Typography>
        <Grid container spacing={3}>
          {papeis.map((papel, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={papeisSelecionados?.some(
                      (papelSelecionado: { id: number }) => papelSelecionado.id === papel.id,
                    )}
                    onChange={(event) => handleChange(event, papel)}
                    name="roles[]"
                    value={papel}
                  />
                }
                label={papel.name}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};
export default RolesCheckboxGrid;
